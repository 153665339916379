import { homeOutline, starOutline } from 'ionicons/icons';
import { find } from 'lodash';
import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import DefaultFavicon from '../../favicon.ico';

import { AppCardsActionEnum } from '@/enums';
import { useI18n } from '@/i18n';
import { useNetworkStore } from '@/store';
import type { AppActionButton, NetworkEntity, NetworkFullSettingsModel } from '@/types';

interface INetworks {
  getMainNetworkAction(networkData: NetworkEntity): {
    left: AppActionButton;
    right: AppActionButton;
  };
  getNetworkStatus(network: NetworkEntity): string;
  getNetworkLogoUrl(networkId: string): string | null;
  getCurrentNetworkFaviconUrl(): string;
}

export function useNetworksHelper(): INetworks {
  const getMainNetworkAction = (networkData: NetworkEntity): { left: AppActionButton; right: AppActionButton } => {
    const { t } = useI18n();
    const networkStore = useNetworkStore();
    const currentNetworkId: ComputedRef<string> = computed(() => networkStore.getNetwork.id);

    const defaultAction: AppActionButton = {
      title: '',
      type: 'main',
      action: AppCardsActionEnum.None,
      icon: '',
      showIcon: false,
      showTooltip: false,
      showTitle: false,
    };

    const actionData: { left: AppActionButton; right: AppActionButton } = {
      left: { ...defaultAction },
      right: { ...defaultAction },
    };

    if (currentNetworkId.value === networkData.id) {
      actionData.right = {
        ...defaultAction,
        title: t('network.chooseModal.currentNetwork'),
        type: 'secondary',
        action: AppCardsActionEnum.None,
      };
    } else {
      actionData.right = {
        ...defaultAction,
        title: t('open'),
        action: AppCardsActionEnum.ChangeNetwork,
      };
    }

    return actionData;
  };

  const getNetworkStatus = (network: NetworkEntity): string => {
    const icons = {
      home: homeOutline,
      star: starOutline,
    };
    if (network?.isHome) {
      return icons.home;
    }

    if (network?.isOfficial) {
      return icons.star;
    }
    return '';
  };

  const getNetworkLogoUrl = (networkId: string): string | null => {
    const networkStore = useNetworkStore();
    const network = find(networkStore.data, (n) => n.id === networkId);
    const currentNetworkId: ComputedRef<string> = computed(() => networkStore.getNetwork.id);
    const currentNetworkSettings: ComputedRef<NetworkFullSettingsModel | null> = computed(() => networkStore.settings);

    const logoByAvailable = (desktopLogo: string | null, faviconLogo: string | null): string | null => {
      if (desktopLogo?.length) {
        return desktopLogo;
      } else if (faviconLogo?.length) {
        return faviconLogo;
      }
      return null;
    };

    if (currentNetworkId.value === networkId) {
      return logoByAvailable(
        currentNetworkSettings.value?.desktopLogo || null,
        currentNetworkSettings.value?.faviconLogo || null
      );
    } else if (network) {
      return logoByAvailable(network?.desktopLogo, network?.faviconLogo);
    } else {
      return null;
    }
  };

  const getCurrentNetworkFaviconUrl = (): string => {
    const networkStore = useNetworkStore();
    const currentNetworkSettings: ComputedRef<NetworkFullSettingsModel | null> = computed(() => networkStore.settings);

    //NOTE: If there is no favicon, then use the desktop logo
    return currentNetworkSettings.value?.faviconLogo || currentNetworkSettings.value?.desktopLogo || DefaultFavicon;
  };

  return {
    getMainNetworkAction,
    getNetworkStatus,
    getNetworkLogoUrl,
    getCurrentNetworkFaviconUrl,
  };
}
