import { cloneDeep, find, unionBy } from 'lodash';
import { defineStore } from 'pinia';

import { $api } from '@/services';
import type { EntityState } from '@/store';
import type { BadgeEntity, ErrorMessageModel, ResponseBadgesModel, ResponseErrorModel } from '@/types';

interface BadgeState extends EntityState<BadgeEntity> {
  badgesRequests: {
    forFetching: number[];
    activeRequests: number[];
  };
}

export const useBadgesStore = defineStore({
  id: 'badges',
  state: (): BadgeState => ({
    data: [],
    errors: [],
    isLoading: false,

    //NOTE: Check for requested badges if they are not already in the store (so as not to cause repeated requests).
    //NOTE: May be removed when canceling repeated requests at axios level is implemented.
    badgesRequests: {
      forFetching: [],
      activeRequests: [],
    },
  }),
  getters: {
    getErrors:
      (state) =>
      (type: string): string[] => {
        let _errors: string[] = [];
        state.errors
          .filter((f: ErrorMessageModel) => f.key === type)
          .forEach(function (m: ErrorMessageModel) {
            _errors = [..._errors, ...m.errors];
          });
        return _errors;
      },
    getBadges: (state) => {
      return state.data;
    },
    getBadgeById:
      (state) =>
      (id: number): BadgeEntity | undefined =>
        find(state.data, (badge) => badge.id === id),
  },
  actions: {
    async badgesAll(): Promise<void> {
      this.errors = [];
      this.isLoading = true;
      const response = await $api.badge.getBadges();

      if (response.statusCode === 200) {
        const model = response as ResponseBadgesModel;
        this.data = model.data;
        this.isLoading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
    },
    async badgesByIds(ids: number[]): Promise<void> {
      this.errors = [];
      this.isLoading = true;
      const response = await $api.badge.getBadgesById(ids);

      if (response.statusCode === 200) {
        const model = response as ResponseBadgesModel;
        this.data = mergeById(this.data, model.data);
        this.isLoading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
    },
    async checkExistBadgesInStore(badgeId: number): Promise<void> {
      const isExist = find(this.data, (n) => n.id === badgeId);
      if (
        isExist ||
        this.badgesRequests.activeRequests.includes(badgeId) ||
        this.badgesRequests.forFetching.includes(badgeId)
      ) {
        return;
      }

      //TODO: @msedov
      // this.badgesRequests.forFetching = [...this.badgesRequests.forFetching, badgeId];
      this.$patch((state) => {
        state.badgesRequests.forFetching = [...state.badgesRequests.forFetching, badgeId];
      });
    },
  },

  persist: true,
});

const mergeById = (a: BadgeEntity[], b: BadgeEntity[]) => {
  return unionBy(a, b, 'id').map((obj) => {
    const match = find(b, { id: obj.id });
    return match ? Object.assign({}, obj, match) : obj;
  });
};
