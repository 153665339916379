<template>
  <!-- Loading state -->
  <template v-if="isLoading">
    <div class="spinner-container" :class="{ 'user-profile-avatar-dimensions': isUserProfileAvatar }">
      <icons-provider :icon-props="{ width: '22', height: '22' }" :name="AppIconsEnum.CircleAnim" />
    </div>
  </template>

  <!-- Loaded state -->
  <template v-else>
    <!-- Error state -->
    <template v-if="hasError">
      <div class="error" :class="{ 'user-profile-avatar-dimensions': isUserProfileAvatar }">
        <ion-icon :class="{ 'swiper-icon': isSwiper }" :icon="icons.alert" />
      </div>
    </template>

    <template v-else>
      <!-- Blurry background container -->
      <div
        ref="blurryContainerRef"
        :class="{
          blurry_container: true,
          center: center,
          withTopBorderRadius: isAnnouncement || isUserProfileCover,
          isWidgetBanner: isWidgetBanner,
          isUserProfileAvatar: isUserProfileAvatar,
          isUserAvatar: isUserAvatar,
        }"
        @click.stop="handleImageClick(index, $event)"
      >
        <!-- LQIP => Original image -->
        <v-lazy-image
          v-if="lqipDidLoad && lqipMedia"
          ref="vLazyImageRef"
          :src="originalMedia"
          :src-placeholder="lqipMedia"
          :alt="name"
          :class="{
            cover: cover,
            'did-load': imageDidLoad,
            'image-viewer': isViewer,
            'user-profile-avatar': isUserProfileAvatar,
            'user-online': isUserProfileAvatar && userIsOnline,
            'default-max-height': !appliedDimensions,
          }"
        />

        <!-- Original image -->
        <ion-img
          v-else-if="imageDidLoad && originalMedia"
          ref="ionImageRef"
          :src="originalMedia"
          :title="name"
          :alt="name"
          :class="{
            cover: cover,
            'image-viewer': isViewer,
            'user-profile-avatar': isUserProfileAvatar,
            'user-online': isUserProfileAvatar && userIsOnline,
            'default-max-height': !appliedDimensions,
          }"
          @ionImgDidLoad="onDidLoad()"
          @ionImgWillLoad="onWillLoad()"
          @ionError="onError($event)"
        />
      </div>
    </template>
  </template>
</template>

<script lang="ts" setup>
import { IonIcon, IonImg } from '@ionic/vue';
import { alertCircleOutline } from 'ionicons/icons';
import { isEqual } from 'lodash';
import VLazyImage from 'v-lazy-image';
import type { ComputedRef, PropType } from 'vue';
import { computed, watch, onMounted, ref } from 'vue';

import { IconsProvider } from '@/components';
import { AppIconsEnum } from '@/enums';
import { filesHybrid } from '@/helpers';
import type { MediaModel } from '@/types';

// Props
const props = defineProps({
  index: {
    type: Number,
    default: () => 0,
  },
  image: {
    type: Object as PropType<MediaModel | null>,
    default: () => undefined,
  },
  url: {
    type: String,
    default: () => undefined,
  },
  internal: {
    type: String,
    default: () => undefined,
  },
  name: {
    type: String,
    default: () => '',
  },
  cover: {
    type: Boolean,
    default: () => false,
  },
  center: {
    type: Boolean,
    default: () => false,
  },
  withText: {
    type: Boolean,
    default: () => false,
  },
  isSwiper: {
    type: Boolean,
    default: () => false,
  },
  isViewer: {
    type: Boolean,
    default: () => false,
  },
  isAnnouncement: {
    type: Boolean,
    default: () => false,
  },
  isUserProfileAvatar: {
    type: Boolean,
    default: () => false,
  },
  isUserProfileCover: {
    type: Boolean,
    default: () => false,
  },
  isUserAvatar: {
    type: Boolean,
    default: () => false,
  },
  userIsOnline: {
    type: Boolean,
    default: () => false,
  },
  isWidgetBanner: {
    type: Boolean,
    default: () => false,
  },
  isLocalFile: {
    type: Boolean,
    default: () => false,
  },
});

// Icons
const icons = { alert: alertCircleOutline };

// Variables
const isLoading = ref<boolean>(false);
const hasError = ref<boolean>(false);
const appliedDimensions = ref<boolean>(false);

const dimensions = ref<{ width: number; height: number }>({
  width: props.image?.width || 0,
  height: props.image?.height || 0,
});

const lqipMedia = ref<string | undefined>(undefined);
const lqipDidLoad = ref<boolean>(false);

const originalMedia = ref<string>('');
const imageDidLoad = ref<boolean>(false);
const originalImage: ComputedRef<string | undefined> = computed(() => props.image?.url || undefined);

// Actions
const handleImageClick = (index: number, event: Event) => {
  if (props.isUserProfileAvatar || props.isUserAvatar) {
    emit('onAvatarClick', event);
    return;
  }

  emit('onView', index);
};

const decisionFunction = async (): Promise<void> => {
  if (props.isLocalFile) {
    originalMedia.value = props.url ?? '';
    isLoading.value = false;
    imageDidLoad.value = true;
    addBlurryBackgroundImage();
    return;
  }

  if (originalImage.value) {
    await getLqip(originalImage.value);
    applyDimensions();
    addBlurryBackgroundImage();

    await loadImage(originalImage.value);
    applyDimensions();
  } else if (props.url) {
    isLoading.value = true;

    await loadImage(props.url);

    applyDimensions();
  } else {
    onError(new Error('No image url provided'));
  }
};

const getLqip = async (url: string): Promise<void> => {
  isLoading.value = true;
  const file = await filesHybrid.readFile(url, props.internal, true);

  if (!file) {
    console.error('LQIP loading error');
    isLoading.value = false;
    lqipDidLoad.value = false;
  } else {
    lqipMedia.value = file;
    isLoading.value = false;
    lqipDidLoad.value = true;
  }
};

const loadImage = async (url: string): Promise<void> => {
  const file = await filesHybrid.readFile(url, props.internal, false);
  if (!file) {
    onError(new Error('Unable to read file'));
    return;
  } else {
    originalMedia.value = file;
    isLoading.value = false;
    imageDidLoad.value = true;
    onDidLoad();
  }
};

const onWillLoad = () => {
  emit('onWillLoad');
};

const onDidLoad = () => {
  emit('onLoad');
};

const onError = (error?: any) => {
  console.error('Image loading error: ', error);
  hasError.value = true;
  isLoading.value = false;
  emit('onError');
};

const removeImageHash = (url: string | undefined = undefined) => {
  if (!url) {
    console.error('RemoveImageHash failed: no URL');
    return;
  }

  const newUrl = new URL(url);
  return `${newUrl.origin}${newUrl.pathname}`;
};

const ionImageRef = ref<typeof IonImg | null>(null);
// const vLazyImageRef = ref<typeof VLazyImage | null>(null);
const vLazyImageRef = ref<any>(null);
const blurryContainerRef = ref<any>(null);

const applyDimensions = () => {
  const { width, height } = dimensions.value;

  if (width === 0 || height === 0) {
    // console.warn('No dimensions to apply'); //! DEBUG
    return;
  }

  const parentContainer = blurryContainerRef.value;
  if (!parentContainer) {
    console.error('No parent container found');
    return;
  }

  const maxWidth = width > parentContainer.offsetWidth ? '100%' : `${width}px`;
  const maxHeight = height > parentContainer.offsetHeight ? '100%' : `${height}px`;

  const styleTag = document.createElement('style');
  styleTag.innerHTML = `
    img {
      max-width: ${maxWidth};
      max-height: ${maxHeight};
    }
  `;

  const vLazyImage = vLazyImageRef.value?.$el;
  if (!vLazyImage) {
    // console.log('No VLazyImage'); //! DEBUG
  } else {
    vLazyImage.setAttribute(
      'style',
      `
      max-width: ${maxWidth};
      max-height: ${maxHeight};
    `
    );
  }

  const ionImageShadowRoot = ionImageRef.value?.$el.shadowRoot;
  if (!ionImageShadowRoot) {
    // console.log('No IonImg ShadowRoot'); //! DEBUG
  } else {
    ionImageShadowRoot.appendChild(styleTag);
    appliedDimensions.value = true;
  }
};

const addBlurryBackgroundImage = (): void => {
  const blurryContainer = blurryContainerRef.value;

  if (props.isViewer) {
    return;
  }

  if (!blurryContainer) {
    console.error('No container found');
    return;
  }

  if (props.isLocalFile) {
    blurryContainer.style.setProperty('--background-image', `url(${originalMedia.value})`);
    return;
  }

  if (lqipMedia.value) {
    blurryContainer.style.setProperty('--background-image', `url(${lqipMedia.value})`);
    return;
  }
};

// Watchers
watch(
  () => originalImage.value,
  async (newImage, oldImage) => {
    const oldImageWithNoHash = removeImageHash(oldImage);
    const newImageWithNoHash = removeImageHash(newImage);

    if (!isEqual(newImageWithNoHash, oldImageWithNoHash)) {
      isLoading.value = false;
      hasError.value = false;
      await decisionFunction();
    }
  }
);

// Lifecycle
onMounted(async () => {
  await decisionFunction();
});

// Emits
const emit = defineEmits(['onView', 'onAvatarClick', 'onWillLoad', 'onLoad', 'onError']);
</script>

<style scoped lang="scss">
.blurry_container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &.withTopBorderRadius {
    border-radius: app-radius(md) app-radius(md) 0 0;
  }

  &.isWidgetBanner {
    border-radius: app-radius(md);
  }

  &.center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.isUserAvatar {
    border-radius: 50%;
  }

  &.isUserProfileAvatar {
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    filter: blur(50px);
    z-index: 1;
    background-image: var(--background-image);
  }

  img,
  ion-img,
  v-lazy-image {
    position: relative;
    z-index: 2;
    display: block;
    margin: auto;
    width: 100%;
    height: 100%;
    object-fit: contain;

    &.default-max-height {
      max-height: 100%;
      max-width: 100%;
    }
  }

  ion-img,
  .v-lazy-image {
    transition: transform 0.2s;
  }

  ion-img.user-profile-avatar,
  .v-lazy-image.user-profile-avatar {
    position: relative;
    width: 10rem;
    height: 10rem;
    object-fit: cover;
    border-radius: 50%;
    max-width: none;

    &::part(image) {
      border-radius: 50%;
    }
  }

  ion-img.cover,
  .v-lazy-image.cover {
    object-fit: cover;
    z-index: 0;
  }

  ion-img.user-online::after {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #4caf50;
    position: absolute;
    right: 10px;
    bottom: 10px;
    z-index: 1;
    border: 4px solid var(--ion-color-light-background-contrast);
  }

  .v-lazy-image {
    filter: blur(10px);
    transition: filter 1.2s;

    &.did-load {
      filter: blur(0);
    }
  }
}
.user-profile-avatar-dimensions {
  width: 10rem !important;
  height: 10rem !important;
}
.spinner-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-width: 100%;
}
.error {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  ion-icon {
    font-size: 3rem;

    &.swiper-icon {
      font-size: 2.5rem;
    }
  }
}
</style>
