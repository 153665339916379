import axios from '@/services/axios';
import type { ResponseErrorModel, ResponseSearchModel } from '@/types';

export class SearchApiService {
  async search(query: string): Promise<ResponseSearchModel | ResponseErrorModel> {
    return axios.get(`/search/all?${query}`);
  }

  async suggest(query: {}): Promise<ResponseSearchModel | ResponseErrorModel> {
    return axios.get('/search/suggest', { params: query });
  }
}
