import axios from '@/services/axios';
import type {
  ResponseErrorModel,
  ResponseSuccessModel,
  ResponseTopicModel,
  ResponseTopicsModel,
  RequestCreateTopicModel,
  TopicColorModel,
} from '@/types';

export class TopicApiService {
  async topicsAutocomplete(searchText: string): Promise<ResponseTopicsModel | ResponseErrorModel> {
    return axios.post(`/topics/autocomplete`, { searchText: searchText });
  }

  async search(text: string): Promise<ResponseTopicsModel | ResponseErrorModel> {
    return axios.get(`/topics/all?search=${text}`);
  }

  async getTopicsAll(): Promise<ResponseTopicsModel | ResponseErrorModel> {
    return axios.get(`/topics/all`);
  }

  async getTopicsFollowing(): Promise<ResponseTopicsModel | ResponseErrorModel> {
    return axios.get(`/topics/following`);
  }

  async getTopicsRecommended(): Promise<ResponseTopicsModel | ResponseErrorModel> {
    return axios.get(`/topics/recommended`);
  }

  async getTopicByLabel(label: string): Promise<ResponseTopicModel | ResponseErrorModel> {
    return axios.get(`/topics/byLabel?label=${label}`);
  }

  async loadMore(url: string): Promise<ResponseTopicsModel | ResponseErrorModel> {
    return axios.get(url);
  }

  async deleteTopic(id: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(`/topics/delete?id=${id}`);
  }

  async editTopic(
    id: number,
    title: string,
    color: TopicColorModel | null,
    description: string
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/topics/edit/${id}`, { title, color, description });
  }

  async createTopic(topicData: RequestCreateTopicModel): Promise<ResponseTopicModel | ResponseErrorModel> {
    return axios.post(`/topics/create`, topicData);
  }

  async autocomplete(searchText: string): Promise<ResponseTopicsModel | ResponseErrorModel> {
    return axios.post('/topics/autocomplete/', { searchText });
  }
}
