<template>
  <icons-provider
    v-if="!item || item?.status === FileStatusEnum.Loading"
    :icon-props="{ width: '16', height: '16' }"
    :name="AppIconsEnum.CircleAnim"
  />

  <div v-if="item?.status === FileStatusEnum.Success && !isImage">
    <icons-provider
      class="file-icon"
      :name="fileType"
      :icon-props="{
        width: '40',
        height: '40',
      }"
    />
    <div class="name">
      {{ item?.name }}
    </div>
    <div v-if="validateSize.length > 0" class="size">
      <span>{{ validateSize }}</span>
    </div>
  </div>

  <div v-else-if="item?.status === FileStatusEnum.Success && item.media && isImage" class="image">
    <media-image :url="item?.media" :name="item?.name" center is-local-file @onView="$emit('onView')" />
  </div>

  <ion-icon v-if="item?.status === FileStatusEnum.Error" :icon="icons.alert" />

  <ion-icon v-if="item?.status === FileStatusEnum.LargeFile" :icon="icons.fileLarge" />
</template>

<script lang="ts" setup>
import { IonIcon } from '@ionic/vue';
import { alertCircleOutline, cloudOfflineOutline } from 'ionicons/icons';
import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import { IconsProvider, MediaImage } from '@/components';
import { AppIconsEnum, FileStatusEnum } from '@/enums';
import { filesHybrid } from '@/helpers';
import type { FilePickModel } from '@/types';

// Props
const props = defineProps({
  payload: {
    type: String,
    required: true,
    default: () => '',
  },
});

// Icons
const icons = {
  alert: alertCircleOutline,
  fileLarge: cloudOfflineOutline,
};

// Variables
const item: ComputedRef<FilePickModel | undefined> = computed(() => {
  return filesHybrid.getByPayload(props.payload);
});

const fileType = computed(() => {
  if (item.value?.type) {
    return item.value.type;
  }
  return '';
});

const validateSize = computed(() => {
  if (item.value?.size !== undefined) {
    let size = item.value.size / 1014;
    if (size >= 1024) {
      size = size * 0.001;
      return size.toFixed(1) + ' MB';
    } else {
      return size.toFixed(1) + ' KB';
    }
  }
  return '';
});

const isImage = computed(() => {
  return item.value?.mimeType.startsWith('image') && !filesHybrid.unsupportedImageFormats.includes(item.value?.type);
});

// Emits
defineEmits(['onView']);
</script>

<style scoped lang="scss">
.name {
  text-align: center;
  width: 72px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.size {
  font-size: 0.8rem;
}
.file-icon {
  font-size: 40px;
}
.image {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  border-radius: app-radius(md);
}
</style>
