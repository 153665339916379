import { cloneDeep } from 'lodash';
import { defineStore } from 'pinia';

import { FeedTypeEnum } from '@/enums';
import { defaultPost } from '@/models';
import { $api } from '@/services';
import type { EntityState } from '@/store';
import { usePostStore } from '@/store';
import type {
  ErrorMessageModel,
  ResponseErrorModel,
  EventEntity,
  ResponseEventModel,
  RequestEventsByPeriodModel,
  RequestEventCreateModel,
  ResponseEventsModel,
  PostEventDataAttendingModel,
  ResponseEventAttendingModel,
  ResponseShortUsersModel,
  UserShortModel,
} from '@/types';

interface EventState extends EntityState<EventEntity> {
  calendarPeriod: { start: string; end: string } | null;
}

export const useEventStore = defineStore({
  id: 'events',
  state: (): EventState => ({
    data: [],
    errors: [],
    isLoading: false,
    calendarPeriod: null,
  }),
  getters: {
    getErrors:
      (state) =>
      (type: string): string[] => {
        let _errors: string[] = [];
        state.errors
          .filter((f: ErrorMessageModel) => f.key === type)
          .forEach(function (m: ErrorMessageModel) {
            _errors = [..._errors, ...m.errors];
          });
        return _errors;
      },
    getEvents(state) {
      return state.data;
    },
    getEventById:
      (state) =>
      (id: number): EventEntity => {
        const index = state.data.findIndex((event: EventEntity) => event.id === id);

        if (~index) {
          return state.data[index];
        }
        return cloneDeep(defaultPost);
      },
  },
  actions: {
    async eventsByPeriod(periodData: RequestEventsByPeriodModel): Promise<EventEntity[] | undefined> {
      this.errors = [];
      this.isLoading = true;
      const response = await $api.event.getEventsByPeriod(periodData);

      if (response.statusCode === 200) {
        const model = response as ResponseEventsModel;
        this.data = model.data;
        this.isLoading = false;
        return model.data;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
      return undefined;
    },
    async eventCreate(eventData: RequestEventCreateModel): Promise<boolean> {
      this.errors = [];
      const response = await $api.event.eventCreate(eventData);

      if (response.statusCode === 200) {
        const postStore = usePostStore();
        const model = response as ResponseEventModel;

        const event = cloneDeep(defaultPost);
        this.data.push(Object.assign(event, model.data));

        const postEvent = cloneDeep(defaultPost);
        postStore.data.unshift(
          Object.assign(postEvent, model.data, {
            isDeletable: true,
            messageType: FeedTypeEnum.Event,
            bodyHtml: eventData.text,
            ccUsers: model.data.ccUsers,
          })
        );

        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async eventDelete(eventId: number): Promise<boolean> {
      const postStore = usePostStore();
      this.errors = [];
      const response = await $api.event.eventDelete(eventId);
      if (response.statusCode === 200) {
        const index = this.data.findIndex((n) => n.id === eventId);
        this.data.splice(index, 1);

        const postIndex = postStore.data.findIndex((n) => n.id === eventId);
        if (~postIndex) {
          postStore.data.splice(index, 1);
        }

        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async getEventAttendings(id: number): Promise<PostEventDataAttendingModel[] | undefined> {
      this.errors = [];
      const response = await $api.event.getEventAttendings(id);

      if (response.statusCode === 200) {
        const model = response as ResponseEventAttendingModel;
        return model.data;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return undefined;
    },
    async getEventSubscribers(id: number): Promise<UserShortModel[]> {
      this.errors = [];
      const response = await $api.event.getEventSubscribers(id);

      if (response.statusCode === 200) {
        const model = response as ResponseShortUsersModel;
        return model.data;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return [];
    },
    upsert(event: EventEntity) {
      const index = this.data.findIndex(({ id }) => id === event.id);
      if (~index) {
        this.data[index] = cloneDeep(event);
      } else {
        this.data = [...this.data, event];
      }

      this.$patch({
        errors: [],
      });
    },
  },

  persist: false,
});
