import { cloneDeep } from 'lodash';

import type { DefaultUserItemTypesEnum } from '@/enums';
import {
  AllowExternalUsersToGroupEnum,
  AllowMobileAppEnum,
  AccessByRoleEnum,
  DigestSubscribeEnum,
  DocEditAccessEnum,
  DownloadPostAccessLevel,
  HomePageEnum,
  OrgChartTypeEnum,
  ProfileEditEnum,
  UserAdminAccessLevel,
  UserVerificationType,
  aiAssistantAccessLevelEnum,
  taskManagementAccessLevelEnum,
  UserRoleEnum,
  HeadBackgroundTypeEnum,
} from '@/enums';
import type {
  NetworkBrandingModel,
  NetworkDesignModel,
  NetworkEmailFooterModel,
  NetworkEntity,
  NetworkFullSettingsModel,
  NetworkMobileAppsModel,
} from '@/types';

const defaultImages = {
  faviconLogo: null,
  mobileLogo: null,
  desktopLogo: null,
};

export const defaultNetwork: NetworkEntity = {
  id: '',
  title: '',
  logo: '',
  favicon: '',
  companyType: 0,
  hostWithSubHost: '',
  isHome: false,
  isOfficial: false,
  notificationCount: 0,
  webSocket: import.meta.env.VITE_APP_URL_SIGNALR,
  logoMobile: '',
  ...cloneDeep(defaultImages),
};

export const defaultNetworkEmailFooter: NetworkEmailFooterModel = {
  emailFooter: null,
};

export const defaultNetworkDesign: NetworkDesignModel = {
  headOpacityImage: '',
  headBackgroundType: HeadBackgroundTypeEnum.ExampleColors,
  headDefaultBackgroundFileName: null,
  headBackgroundColor: null,
  headForeColor: '',
  headBorderColor: '',
  headBackgroundFileName: '',
  logonBackgroundImage: '',
  documentTemplateFilename: null,
  ...cloneDeep(defaultImages),
};

export const defaultNetworkMobileApps: NetworkMobileAppsModel = {
  appleAppID: '',
  googleAppID: '',
  appleMessengerID: '',
  googleMessengerID: '',
  identityTitle: '',
};

export const defaultNetworkBranding: NetworkBrandingModel = {
  linkIdentity: '',
  linkAbout: '',
  linkToSupport: '',
  supportEmail: '',
  linkUserAgreement: '',
};

export const defaultNetworkSettings: NetworkFullSettingsModel = {
  userVerificationType: UserVerificationType.VerificationByCompanyEmailSuffix,
  isRequireAcceptPolicy: false,
  defaultDigestSubscribe: DigestSubscribeEnum.Never,
  disableInvitations: false,
  locale: null,
  editProfile: ProfileEditEnum.None,
  allowMobileApp: AllowMobileAppEnum.Off,
  publishUserItemAboutNewUser: false,
  showUsersEmails: false,
  showOnlineUsers: false,
  showFollowLists: false,
  allowPostToFeed: false,
  allowPostOnBehalf: false,
  showAttachedImagesInDocBrowser: false,
  everyoneCanCreateGroups: false,
  onlyAdminsCanCreateTags: false,
  fieldAddingEnabled: false,
  enableRequestsRegistrationToNetwork: false,
  activation_SkipGroups: false,
  activation_SkipInvites: false,
  activation_SkipPhoto: false,
  useLogo: false,
  orgChartType: OrgChartTypeEnum.Off,
  registrationMode: '',
  docEditAccess: DocEditAccessEnum.Off,
  digestTemplateEditor: false,
  isEveryoneCanDeactivateUsers: false,
  isAdvancedWikiesEditor: false,
  sendFullMessageTextInNotif: false,
  allowGroupsFineTuning: false,
  enableChat: false,
  enableVideoChat: false,
  removeTempChat: false,
  allowMandantGroup: false,
  switchNotificationsToPush: false,
  resultFieldForIdeas: false,
  externalCanCreateIdeas: false,
  taskManagementAccessLevel: taskManagementAccessLevelEnum.None,
  aiAssistantAccessLevel: aiAssistantAccessLevelEnum.None,
  enableCustomCompanyBackground: false,
  customCompanyBackgroundFileName: null,
  customCompanyBackgroundDarkThemeFileName: null,
  customCompanyBackgroundType: null,
  customCompanyBackgroundColor: null,
  allowUserRoleFineTuning: false,
  allowExternalUsersToGroup: AllowExternalUsersToGroupEnum.Off,
  userExternalRoleId: UserRoleEnum.ExternalGroupUserReadLike,
  allowGroupAdminToSetDefaultRole: false,
  downloadPostAccessLevel: DownloadPostAccessLevel.None,
  sendArchivedPost: UserAdminAccessLevel.None,
  editPostsTimeOutMinutes: 0,
  defaultUserItemTypes: [] as DefaultUserItemTypesEnum[],
  showIFollow: false,
  userItemLinkExpiration: 0,
  uploadFileSizeLimitMb: 0,
  logo: null,
  allowSeeDocHistory: AccessByRoleEnum.Off,
  homePageJson: {
    type: HomePageEnum.DefaultNewsFeed,
    id: 0,
    title: '',
    isPin: false,
  },
  isDefaultNetwork: false,
  isOfficialNetwork: false,
  title: '',
  onlyForAdminsBadgesManaging: false,
  searchSuggestions: AccessByRoleEnum.AdminOrHigher,
  defaultEnableNotificationsOnJoin: true,
  ...cloneDeep(defaultNetworkDesign),
  ...cloneDeep(defaultNetworkMobileApps),
  ...cloneDeep(defaultNetworkBranding),
  ...cloneDeep(defaultNetworkEmailFooter),
};
