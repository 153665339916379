<template>
  <ion-button
    v-if="postType === PostTypeActionEnum.Comment || postType === PostTypeActionEnum.TaskComment"
    slot="end"
    :class="['comment-button', { 'is-small': isSmall }]"
    mode="md"
    fill="clear"
    :disabled="disabled"
    color="intra"
    @click="actionSend"
  >
    <icons-provider
      slot="icon-only"
      :icon-props="{
        width: '22',
        height: '22',
        fill: 'var(--ion-color-intra)',
      }"
      :name="isLoading ? 'circleAnim' : 'send'"
    />
  </ion-button>

  <ion-button v-else class="post-button" :disabled="disabled" mode="md" @click="actionSend">
    <icons-provider
      slot="icon-only"
      :icon-props="{
        width: '22',
        height: '22',
        fill: 'var(--ion-color-custom)',
      }"
      :name="isLoading ? 'circleAnim' : 'send'"
    />
  </ion-button>
</template>

<script lang="ts" setup>
import { IonButton } from '@ionic/vue';
import type { PropType } from 'vue';

import { IconsProvider } from '@/components';
import { PostTypeActionEnum } from '@/enums';

defineProps({
  postType: {
    type: String as PropType<PostTypeActionEnum>,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: () => false,
  },
  isLoading: {
    type: Boolean,
    default: () => false,
  },
  isSmall: {
    type: Boolean,
    default: () => false,
  },
});

const emit = defineEmits(['onSendClick']);
const actionSend = async () => {
  emit('onSendClick');
};
</script>
<style scoped lang="scss">
ion-button.post-button {
  --color: var(--ion-color-custom);
  --background: var(--ion-color-intra);
}
ion-button.comment-button.is-small {
  width: 40px !important;
  --padding-top: 0 !important;
  --padding-bottom: 0 !important;
}
</style>
