import { Clipboard } from '@capacitor/clipboard';
import { type ToastButton, toastController } from '@ionic/vue';
import type { ErrorEvent } from '@sentry/types';
import type { AxiosError } from 'axios';
import { closeCircleOutline, checkmarkOutline, copyOutline, linkOutline, warningOutline } from 'ionicons/icons';

import { SentrySibling } from '../plugins/sentry';

import { isAnyMobile } from './helper';
import { logWarn, logErr, logInfo } from './logger';

import { RequirementsEnum, ResponseErrorTypeEnum } from '@/enums';
import { useRequirementsHelper } from '@/helpers';
import { useAppStore, useMessengerStore, useNetworkStore } from '@/store';
import type { ResponseErrorModel } from '@/types';

export type IErrors = {
  handleError: (
    showUser: boolean,
    error: AxiosError<ResponseErrorModel> | undefined | any,
    message?: string
  ) => ResponseErrorTypeEnum | string;
  setSentryEvent: (event: ErrorEvent) => Promise<void>;
  handleSuccessfulSubmit: () => Promise<void>;
  newShowToast: (
    id: string,
    message: string,
    isSuccess: boolean,
    sentryDetails?: ErrorEvent | null,
    serverDetails?: ResponseErrorModel | null
  ) => Promise<void>;
  dismissCurrentError: () => Promise<void>;
};

export type CurrentErrorEvent = {
  id: string;
  message: string;
  serverDetails: ResponseErrorModel | null;
  sentryDetails: ErrorEvent | null;
  showUser?: boolean;
};

let instance: IErrors | null = null;
export function useErrors(): IErrors {
  if (instance) {
    return instance;
  }

  /**
   * --------------------------------------------------------------------------------
   * Stores
   * --------------------------------------------------------------------------------
   */
  const networkStore = useNetworkStore();
  const appStore = useAppStore();
  const messengerStore = useMessengerStore();

  /**
   * --------------------------------------------------------------------------------
   * Variables
   * --------------------------------------------------------------------------------
   */
  let movedToastTo = 0;
  let currentError: CurrentErrorEvent = {
    id: '',
    message: '',
    serverDetails: null,
    sentryDetails: null,
    showUser: false,
  };

  /**
   * --------------------------------------------------------------------------------
   * Private methods
   * --------------------------------------------------------------------------------
   */
  const _resetCurrentError = (): void => {
    Object.assign(currentError, {
      id: '',
      message: '',
      serverDetails: null,
      sentryDetails: null,
      showUser: false,
    });
    movedToastTo = 0;
  };

  /**
   * --------------------------------------------------------------------------------
   * Error Handlers
   * --------------------------------------------------------------------------------
   */
  const _handleBadRequest = async (error: CurrentErrorEvent): Promise<void> => {
    logErr('Error type is BadRequest or ApiNotValidModel', error);

    await newShowToast(error.id, error.message, false, error.sentryDetails, error.serverDetails);
  };

  const _handleUnauthorized = async (error: CurrentErrorEvent): Promise<void> => {
    logErr('Error type is Unauthorized', error);

    await newShowToast(error.id, error.message, false, error.sentryDetails, error.serverDetails);
  };

  const _handleAccessDenied = async (error: CurrentErrorEvent): Promise<void> => {
    networkStore.isNetworkAvailable = false;
    await newShowToast(error.id, error.message, false, error.sentryDetails, error.serverDetails);
    // TODO: Handle access denial, possibly log out the user.
  };

  const _handleInvalidAcceptPolicy = async (error: CurrentErrorEvent): Promise<void> => {
    logErr('Error type is InvalidAcceptPolicy', error);

    appStore.isLoading = false;
    networkStore.isLoading = false;
    messengerStore.isLoading = false;
    await useRequirementsHelper().check(RequirementsEnum.UsageRules);
  };

  /**
   * !: Temporary disabled
  const _handleNotFound = async (error: CurrentErrorEvent): Promise<void> => {
    logErr('Error type is NotFound', error);

    await newShowToast(error.id, error.message, false, error.sentryDetails, error.serverDetails);
  };
  */

  const _handleRequestTimeout = async (error: CurrentErrorEvent): Promise<void> => {
    logErr('Error type is RequestTimeout', error);

    await newShowToast(error.id, error.message, false, error.sentryDetails, error.serverDetails);
  };

  const _handleConflict = async (error: CurrentErrorEvent): Promise<void> => {
    logErr('Error type is Conflict', error);

    await newShowToast(error.id, error.message, false, error.sentryDetails, error.serverDetails);
  };

  const _handleInternalServerError = async (error: CurrentErrorEvent): Promise<void> => {
    logErr('Error type is InternalServerError', error);

    await newShowToast(error.id, error.message, false, error.sentryDetails, error.serverDetails);
  };

  const _handleBadGateway = async (error: CurrentErrorEvent): Promise<void> => {
    logErr('Error type is BadGateway', error);

    await newShowToast(error.id, error.message, false, error.sentryDetails, error.serverDetails);
  };

  const _handleServiceUnavailable = async (error: CurrentErrorEvent): Promise<void> => {
    logErr('Error type is ServiceUnavailable', error);

    await newShowToast(error.id, error.message, false, error.sentryDetails, error.serverDetails);
  };

  const _handleGatewayTimeout = async (error: CurrentErrorEvent): Promise<void> => {
    logErr('Error type is GatewayTimeout', error);

    await newShowToast(error.id, error.message, false, error.sentryDetails, error.serverDetails);
  };

  const _handleUnknownError = async (error: CurrentErrorEvent): Promise<void> => {
    logErr('Error type is UnknownError', error);

    const errorType = (error.serverDetails?.errorType as ResponseErrorTypeEnum) || ResponseErrorTypeEnum.UnknownError;
    let message: string = error.message;
    if (errorType === ResponseErrorTypeEnum.UnknownError && !message && error.sentryDetails) {
      const sentryMsg = error.sentryDetails?.message as any;
      if (!sentryMsg) {
        message = 'Unknown error';
      }

      message = `
          status: ${sentryMsg.status ?? 'Unknown status'}
          statusText: ${sentryMsg.statusText ?? 'Unknown status text'}
          event_id: ${error.sentryDetails?.event_id ?? 'Unknown event id'}
          time: ${sentryMsg.time ?? new Date().toISOString()}
        `;
    }
    await newShowToast(
      error.id,
      message || 'Internal error. Please try again later or contact support.',
      false,
      error.sentryDetails,
      error.serverDetails
    );
  };

  const _handleTurnedOffTypes = (error: CurrentErrorEvent): void => {
    logWarn(`This type of error is currently turned off: ${error.serverDetails?.errorType}`);
  };

  const _errorHandlers: Record<ResponseErrorTypeEnum, (error: CurrentErrorEvent) => Promise<void> | void> = {
    [ResponseErrorTypeEnum.BadRequest]: _handleBadRequest,
    [ResponseErrorTypeEnum.ApiNotValidModel]: _handleBadRequest,
    [ResponseErrorTypeEnum.Unauthorized]: _handleUnauthorized,
    [ResponseErrorTypeEnum.InvalidAcceptPolicy]: _handleInvalidAcceptPolicy,
    [ResponseErrorTypeEnum.AccessDenied]: _handleTurnedOffTypes, //! DISABLED
    [ResponseErrorTypeEnum.AccessDeniedForUser]: _handleTurnedOffTypes, //! DISABLED
    [ResponseErrorTypeEnum.Forbidden]: _handleTurnedOffTypes, //! DISABLED
    [ResponseErrorTypeEnum.NotFound]: _handleTurnedOffTypes, //! DISABLED
    [ResponseErrorTypeEnum.MethodNotAllowed]: _handleAccessDenied,
    [ResponseErrorTypeEnum.RequestTimeout]: _handleRequestTimeout,
    [ResponseErrorTypeEnum.Conflict]: _handleConflict,
    [ResponseErrorTypeEnum.InternalServerError]: _handleInternalServerError,
    [ResponseErrorTypeEnum.BadGateway]: _handleBadGateway,
    [ResponseErrorTypeEnum.ServiceUnavailable]: _handleServiceUnavailable,
    [ResponseErrorTypeEnum.GatewayTimeout]: _handleGatewayTimeout,
    [ResponseErrorTypeEnum.UnknownError]: _handleUnknownError,

    //TODO: Additional server unavailable error types
    [ResponseErrorTypeEnum.ERR_FR_TOO_MANY_REDIRECTS]: _handleUnknownError,
    [ResponseErrorTypeEnum.ERR_BAD_OPTION_VALUE]: _handleUnknownError,
    [ResponseErrorTypeEnum.ERR_BAD_OPTION]: _handleUnknownError,
    [ResponseErrorTypeEnum.ERR_NETWORK]: _handleUnknownError,
    [ResponseErrorTypeEnum.ERR_DEPRECATED]: _handleUnknownError,
    [ResponseErrorTypeEnum.ERR_BAD_RESPONSE]: _handleUnknownError,
    [ResponseErrorTypeEnum.ERR_BAD_REQUEST]: _handleUnknownError,
    [ResponseErrorTypeEnum.ERR_NOT_SUPPORT]: _handleUnknownError,
    [ResponseErrorTypeEnum.ERR_INVALID_URL]: _handleUnknownError,
    [ResponseErrorTypeEnum.ERR_CANCELED]: _handleUnknownError,
    [ResponseErrorTypeEnum.ECONNABORTED]: _handleUnknownError,
    [ResponseErrorTypeEnum.ETIMEDOUT]: _handleUnknownError,
  };

  const _showErrorEvent = async (error: CurrentErrorEvent): Promise<void> => {
    const errorType = (error.serverDetails?.errorType as ResponseErrorTypeEnum) || ResponseErrorTypeEnum.UnknownError;
    const handler = _errorHandlers[errorType];

    if (handler) {
      await handler(error);
    } else {
      logErr('No handler for error type:', errorType);
      await _handleUnknownError(error);
    }
  };

  /**
   * --------------------------------------------------------------------------------
   * Public methods
   * --------------------------------------------------------------------------------
   */
  const newShowToast = async (
    id: string,
    message: string,
    isSuccess: boolean,
    sentryDetails?: ErrorEvent | null,
    serverDetails?: ResponseErrorModel | null
  ): Promise<void> => {
    toastController.getTop().then((t) => {
      if (t) {
        if (t.id === id || t.message === message) {
          t.dismiss();
        } else {
          movedToastTo =
            document.getElementById(`${t.id}`)?.shadowRoot?.querySelector('.toast-wrapper')?.getBoundingClientRect()
              .height || movedToastTo;
        }
      }
    });

    const toastButtons: ToastButton[] = [
      {
        text: '',
        icon: linkOutline,
        role: 'link',
        side: 'end',
        handler: async () => {
          const link = sentryDetails?.event_id ?? serverDetails?.traceId ?? new Date().toISOString();
          if (isAnyMobile) {
            await Clipboard.write({ string: link });
          } else {
            navigator.clipboard.writeText(link);
          }

          await toast.dismiss();
        },
      },
      {
        text: '',
        icon: copyOutline,
        role: 'copy',
        side: 'end',
        handler: async () => {
          const string = `[${JSON.stringify(sentryDetails, null, 2)},\n${JSON.stringify(serverDetails, null, 2)}]`;
          if (isAnyMobile) {
            await Clipboard.write({ string });
          } else {
            navigator.clipboard.writeText(string);
          }

          await toast.dismiss();
        },
      },
      {
        text: '',
        icon: closeCircleOutline,
        role: 'close',
        side: 'end',
        handler: async () => {
          await toast.dismiss();
        },
      },
    ];

    const toast = await toastController.create({
      id: id,
      mode: 'md',
      message: message,
      duration: isSuccess ? 3000 : 0,
      animated: true,
      position: 'top',
      icon: isSuccess ? checkmarkOutline : warningOutline,
      cssClass: ['custom_toast', isSuccess ? 'success' : 'fail'],
      buttons: toastButtons,
    });

    toast.addEventListener('ionToastWillPresent', async () => {
      logInfo('Moving toast to top...');
      if (movedToastTo) {
        toast.style.marginTop = `${movedToastTo + 10}px`;
      }
    });

    toast.onDidDismiss().then(async () => {
      logInfo('Resetting currentError...');
      _resetCurrentError();
    });
    await toast.present();
  };

  const handleError = (
    showUser: boolean,
    error: AxiosError<ResponseErrorModel | any> | undefined,
    message?: string
  ): ResponseErrorTypeEnum | string => {
    const errorMessages = error?.response?.data?.errorMessages;
    const errorMessage = errorMessages?.[0]?.errors?.[0] || message || error?.cause?.message || '';

    const statusCode =
      error?.response?.data?.statusCode || //NOTE: Our custom prop - statusCode from ResponseErrorModel
      error?.response?.status || //NOTE: Axios error status
      error?.code || //NOTE: If no response, then Axios error code
      'unknown statusCode';

    const traceId =
      error?.response?.data?.traceId || //NOTE: Our custom prop - traceId from ResponseErrorModel
      error?.response?.config?.url || //NOTE: Axios error config
      error?.config?.url || //NOTE: If no response, then Axios error config
      'unknown traceId';

    const errorType =
      error?.response?.data?.errorType || //NOTE: Our custom prop - errorType from ResponseErrorModel
      error?.response?.statusText || //NOTE: Axios error statusText
      error?.code || //NOTE: If no response, then Axios error code
      ResponseErrorTypeEnum.UnknownError;

    currentError = {
      id: '',
      message: errorMessage,
      serverDetails: {
        statusCode,
        traceId,
        errorType,
        errorMessages: errorMessages || [],
      },
      sentryDetails: null,
      showUser,
    };
    logErr('currentError', currentError); //! DEBUG

    SentrySibling.captureException(error);
    return errorType;
  };

  const setSentryEvent = async (event: ErrorEvent): Promise<void> => {
    currentError.id = event?.event_id || 'unknown event_id';
    currentError.sentryDetails = event;

    if (currentError.showUser) {
      await _showErrorEvent(currentError);
    }
  };

  const handleSuccessfulSubmit = async (): Promise<void> => {
    await newShowToast(
      currentError.id,
      'We received your report and already working on it!', //TODO: Translate message t('apiErrors.reportReceived')
      true,
      currentError.sentryDetails,
      currentError.serverDetails
    );
  };

  const dismissCurrentError = async (): Promise<void> => {
    logInfo('Dismissing current error...');

    try {
      const currentToast = await toastController.getTop();
      if (currentError && currentToast) {
        if (currentError.id === currentToast.id) {
          await toastController.dismiss(currentToast.id);
        }
      }
    } catch (e) {
      logErr('Error while dismissing current error:', e);
    }
  };

  instance = {
    handleError,
    setSentryEvent,
    newShowToast,
    handleSuccessfulSubmit,
    dismissCurrentError,
  };

  return instance;
}
