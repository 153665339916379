import { PDFDocument } from 'pdf-lib';

export type IUsePdfViewer = {
  /**
   * @description Function to modify the PDF title before opening
   * @param pdfBlob - The PDF Blob to modify
   * @param newTitle - The new title to set in the PDF
   * @returns A Promise with the modified PDF Blob
   */
  modifyPdfTitle: (pdfBlob: Blob, newTitle: string) => Promise<Blob>;
};

/**
 * @description Helper for working with PDF files
 */
export const usePdfViewer = (): IUsePdfViewer => {
  const modifyPdfTitle = async (pdfBlob: Blob, newTitle: string): Promise<Blob> => {
    // Convert Blob to ArrayBuffer to work with pdf-lib
    const arrayBuffer = await pdfBlob.arrayBuffer();
    const pdfDoc = await PDFDocument.load(arrayBuffer);

    // Set the new document title
    pdfDoc.setTitle(newTitle);
    // Set the new title in the PDF metadata and indicate it should appear in the title bar
    // pdfDoc.setTitle(newTitle, { showInWindowTitleBar: true });

    // Save the modified PDF as Uint8Array
    const modifiedPdfBytes = await pdfDoc.save();

    // Return a new Blob from the modified PDF
    return new Blob([modifiedPdfBytes], { type: 'application/pdf' });
  };

  return {
    modifyPdfTitle,
  };
};
