import { componentTagsPickerModal } from './modalComponents';
import { componentTagsPickerPopover } from './popoverComponents';

import { useAppStore } from '@/store';
import type { TopicEntity } from '@/types';

export type IUseTags = {
  tagsPicker(
    ev: Event,
    selectedTags: TopicEntity[],
    multiple: boolean,
    editable: boolean
  ): Promise<TopicEntity[] | undefined>;
};

export function useTags(): IUseTags {
  const tagsPicker = async (
    ev: Event,
    selectedTags: TopicEntity[],
    multiple: boolean,
    editable: boolean
  ): Promise<TopicEntity[] | undefined> => {
    const appStore = useAppStore();

    const result = appStore.isMDWidth
      ? await componentTagsPickerPopover(ev, selectedTags, multiple, editable)
      : await componentTagsPickerModal(selectedTags, multiple, editable);
    return result.data;
  };

  return {
    tagsPicker,
  };
}
