import { isPlatform } from '@ionic/core';

import axios from '@/services/axios';
// import { setupAxiosMocks } from '@/services/axios.test';
import type {
  ResponseAuthCoreTokenModel,
  RequestTokenUserModel,
  RequestAuthNetworkUser,
  RequestAuthHomeUser,
  ResponseAuthUserTokenModel,
  ResponseNetworkLinkModel,
  ResponseErrorModel,
  ResponseNetworksModel,
} from '@/types';

export class AuthApiService {
  clientSecret: string = isPlatform('ios')
    ? import.meta.env.VITE_AXIOS_CLIENT_SECRET_IOS
    : import.meta.env.VITE_AXIOS_CLIENT_SECRET_WEB;

  async oauth(): Promise<ResponseNetworkLinkModel | ResponseErrorModel> {
    return axios.get(`/oauth/link?clientSecret=${this.clientSecret}&type=ApiCore`);
  }
  async networks(params: RequestAuthNetworkUser): Promise<ResponseNetworksModel | ResponseErrorModel> {
    // setupAxiosMocks('post', '/oauth/networks', false, false, 401);
    return axios.post(`/oauth/networks`, { ...params, clientSecret: this.clientSecret });
  }
  async homeCode(params: RequestAuthHomeUser): Promise<ResponseAuthCoreTokenModel | ResponseErrorModel> {
    return axios.post('/oauth/homeCode', { ...params, clientSecret: this.clientSecret });
  }
  async token(params: RequestTokenUserModel): Promise<ResponseAuthUserTokenModel | ResponseErrorModel> {
    return axios.post('/oauth/token', { ...params, clientSecret: this.clientSecret });
  }
}
