import {
  cloneDeep,
  differenceWith,
  filter,
  find,
  fromPairs,
  includes,
  indexOf,
  isEqual,
  orderBy,
  remove,
  toPairs,
  unionBy,
} from 'lodash';
import { defineStore } from 'pinia';

import type { DocumentExtensionEnum, WikiMajorFilterEnum, WikiSaveModeEnum } from '@/enums';
import { WikiJsonTextEnum, AutoUpdateStatusEnum, WikiVersionEnum, WikiLockStatusEnum } from '@/enums';
import { useRichTextEditor } from '@/helpers';
import { useI18n } from '@/i18n';
import {
  defaultAdvancedWikiBodyModel,
  defaultAdvancedWikiModel,
  defaultEditFormModel,
  defaultWikisIds,
} from '@/models';
import { $api } from '@/services';
import { useDocStore, useGroupsStore, useUserStore } from '@/store';
import type {
  TopicEntity,
  WikiDraftModel,
  WikiModel,
  WikiHistoryModel,
  CreateWikiPayload,
  UpdateWikiPayload,
  UpdateWikiDraftPayload,
  WikiRelationsModel,
  WikiGitDiffModel,
  ResponseWikiModel,
  ResponseWikiDraftModel,
  ResponseWikiRelationsModel,
  ResponseWikiHistoryModel,
  ResponseWikiHistoryByIdModel,
  ResponseErrorModel,
  AdvancedWikiContentModel,
  ResponseWikiDraftUpdateModel,
  ResponseWikiCreateModel,
  WikiEditFormModel,
  UserShortModel,
  WikisIdsModel,
  ResponseWikisModel,
  ShortWikisModel,
  UserEntity,
  WikiHistoricalModel,
  ResponseWikiFollowersModel,
  WikiTemplateModel,
  ResponseWikiTemplatesModel,
  SaveWikiTemplateModel,
  ResponseWikiTemplateModel,
  CreateWikiTemplateModel,
  ResponseLockModel,
} from '@/types';

type AdvancedWikiModelKeys = keyof AdvancedWikiContentModel;

export type WikiState = {
  // General
  newId: number | null;
  hasChanged: boolean;
  isLoading: boolean;
  saveLoading: boolean;
  isModalMode: boolean;
  typeToDisable: WikiSaveModeEnum | undefined;

  // Edit Form
  editMode: boolean;
  editForm: WikiEditFormModel;

  // Existing
  existingWiki: WikiModel | null;

  // Relations
  relations: WikiRelationsModel;

  // Followers
  wikiFollowers: UserEntity[];

  // History
  history: WikiHistoryModel[];
  historicalWikis: WikiHistoricalModel[];
  historicalWiki: WikiModel | null;
  isOutdated: boolean;
  versionId: number | null;

  // Compare
  currentGitDiff: WikiGitDiffModel;
  sourceVersion: WikiHistoryModel | null;
  targetVersion: WikiHistoryModel | null;
  comparableWikiName: string;

  // Drafts
  draftWiki: WikiDraftModel | null;
  isDraftDeleted: boolean;
  autoUpdateStatus: AutoUpdateStatusEnum;

  // Templates
  //TODO: currentTemplate: ? | null;
  //TODO: templateId

  // Tags
  // Lock
  // Contributions

  // From AI
  messageFromAi: { name: string; content: string } | null; //TODO: Should work with AdvancedWikiContentModel

  // Search
  data: WikiModel[];
  wikisIds: WikisIdsModel;

  // Wiki templates
  templates: { data: WikiTemplateModel[]; loadMoreUrl: string | null };
};

export const useWikiStore = defineStore({
  id: 'wiki',

  state: (): WikiState => ({
    // General
    newId: null,
    hasChanged: false,
    isLoading: false,
    saveLoading: false,
    isModalMode: false,
    typeToDisable: undefined,
    // Edit Form
    editMode: false,
    editForm: cloneDeep(defaultEditFormModel),
    // Existing
    existingWiki: null,
    // Relations
    relations: {
      data: [],
      loadMoreUrl: null,
    },
    // Followers
    wikiFollowers: [],
    // History
    history: [],
    historicalWikis: [],
    historicalWiki: null,
    isOutdated: false,
    versionId: null,
    // Compare
    currentGitDiff: { sourceId: null, targetId: null, diff: '' },
    sourceVersion: null,
    targetVersion: null,
    comparableWikiName: '',
    // Drafts
    draftWiki: null,
    isDraftDeleted: false,
    autoUpdateStatus: AutoUpdateStatusEnum.Done,
    // Templates
    //TODO: currentTemplate: null,
    //TODO: templateId: null,
    // Tags
    //TODO: tags: [],
    // Lock
    //TODO: lock: false
    // Contributions
    //TODO: contributions: [],
    // From AI
    messageFromAi: null, //TODO: Should work with AdvancedWikiContentModel

    // Search
    data: [],
    wikisIds: cloneDeep(defaultWikisIds),

    // Wiki templates
    templates: { data: [], loadMoreUrl: null },
  }),

  getters: {
    // Edit Form
    getGroupTitle: (state): string => {
      const id = state.editForm.groupId;
      return id ? useGroupsStore().getGroupById(id).title : '';
    },
    getFolderTitle: (state): string => {
      const id = state.editForm.folderId;
      return id ? useDocStore().getFolderTitle(id) : '';
    },
    // Existing Wiki
    isSimple: (state): boolean => !!state.existingWiki && state.existingWiki.version === WikiVersionEnum.V1,
    isAdvanced: (state): boolean => !!state.existingWiki && state.existingWiki.version === WikiVersionEnum.V2,
    isOfficial: (state): boolean => state.existingWiki?.isOfficial || false,
    getLastEditor: (state): UserShortModel | null =>
      state.existingWiki?.editedBy || state.existingWiki?.modifier || null,
    getLastEditDate: (state): string => state.existingWiki?.editedAt || state.existingWiki?.modifiedAt || '',
    getCreatedBy: (state): UserShortModel | null => (state.existingWiki ? state.existingWiki.createdBy : null),
    getCreationDate: (state): string => state.existingWiki?.createdAt || '',
    // Search
    getSearchedWikis: (state) => (): ShortWikisModel => {
      const result = { data: [], loadMoreUrl: null } as ShortWikisModel;
      const data = orderBy(state.data, (obj) => indexOf(state.wikisIds.search.ids, obj.id));
      result.data = filter(data, (obj) => includes(state.wikisIds.search.ids, obj.id));
      result.loadMoreUrl = state.wikisIds.search.loadMoreUrl;
      return result;
    },
    getHistoryWikiById:
      (state) =>
      (id: number): WikiHistoricalModel | null =>
        state.history.find((wiki) => wiki.id === id) || null,
    getTemplates: (state): WikiTemplateModel[] => state.templates.data,
    getTemplatesLoadMoreUrl: (state): string | null => state.templates.loadMoreUrl,
  },

  actions: {
    setDataOnCreate(name: string, groupId: number | null, folderId: number | null): void {
      this.editForm.name = name;
      this.editForm.groupId = groupId;
      this.editForm.folderId = folderId;
      const myId = useUserStore().current?.id;
      if (myId) {
        this.editForm.participantsIds = [myId];
      }
    },
    resetEditForm(): void {
      this.editForm = cloneDeep(defaultEditFormModel);
      this.hasChanged = false;
    },
    resetFromPreviewPage(): void {
      this.existingWiki = null;
      this.history = [];
      this.historicalWikis = [];
      this.historicalWiki = null;
      this.isOutdated = false;
      this.versionId = null;
    },
    resetFromEditPage(): void {
      this.resetEditForm();
      this.resetWikiFromAi();
      this.existingWiki = null;
      this.draftWiki = null;
    },
    resetFromCreatePage(): void {
      this.resetEditForm();
      this.resetWikiFromAi();
      this.draftWiki = null;
      this.newId = null;
      this.isModalMode = false;
    },
    resetFromComparePage(): void {
      this.currentGitDiff = { sourceId: null, targetId: null, diff: '' };
      this.sourceVersion = null;
      this.targetVersion = null;
      this.comparableWikiName = '';
    },

    //!: Should be deprecated later
    setSimpleWiki(prop: 'name' | 'text', value: string): void {
      if (prop === 'name') {
        this.editForm.name = value;
      } else {
        this.editForm.wikiText = value;
      }
    },
    setSimpleContentToBody(text: string): void {
      this.editForm.wikiContent.body[0].text = text;
    },

    setAdvancedWiki(wiki: WikiModel): void {
      this.editForm = {
        name: wiki.name,
        groupId: wiki.group?.id ?? null,
        folderId: wiki.parentFolderId,
        wikiText: wiki.wikiText,
        wikiContent: wiki.wikiContent,
        participantsIds: wiki.wikiContent.participants?.users?.map((user) => user.id) || [],
        // accessOnlyForGroupId: null, //TODO: Add real functionality
        // mentionedUserIds: [], //TODO: Add real functionality
      };
    },
    setAdvancedWikiContent(content: AdvancedWikiContentModel): void {
      this.editForm.wikiContent = content;
    },
    addAdvancedWikiBodyItem(position: number): void {
      const newBodyItem = cloneDeep(defaultAdvancedWikiBodyModel);
      // newBodyItem.name = `${useI18n().t('wiki.advancedMode.bodyTitle')}_${position + 1}`;
      newBodyItem.settings.position = position;
      this.editForm.wikiContent.body.push(newBodyItem);
    },
    removeAdvancedWikiBodyItem(index: number): void {
      this.editForm.wikiContent.body.splice(index, 1);
    },
    /**
     * @description Sets default titles for each section of advanced wiki model
     * @deprecated
     * @see src/views/Wikis/WikiCreatePage.vue - onIonViewWillEnter
     */
    setDefaultAdvancedWikiModel(): void {
      for (const prop of Object.keys(this.editForm.wikiContent) as AdvancedWikiModelKeys[]) {
        const section = this.editForm.wikiContent[prop];
        if (!section) continue;

        switch (prop) {
          case WikiJsonTextEnum.Head:
            this.editForm.wikiContent.head.name = useI18n().t('wiki.editFields.headTitle');
            break;
          case WikiJsonTextEnum.Content:
            this.editForm.wikiContent.content.name = useI18n().t('wiki.editFields.contentTitle');
            break;
          case WikiJsonTextEnum.Body:
            this.editForm.wikiContent.body.forEach((item, index) => {
              item.name = `${useI18n().t('wiki.editFields.bodyTitle')}_${index + 1}`;
            });
            break;
          case WikiJsonTextEnum.Participants:
            if (this.editForm.wikiContent.participants) {
              this.editForm.wikiContent.participants.name = useI18n().t('wiki.editFields.participants.title');
            }
            break;
          default:
            break;
        }
      }
    },
    setVisibility(section: WikiJsonTextEnum, value: boolean, index: number): void {
      // this.editForm.advancedWikiModel[section][index].settings.isVisible = value;
      switch (section) {
        case WikiJsonTextEnum.Head:
          this.editForm.wikiContent.head.settings.isVisible = value;
          break;
        case WikiJsonTextEnum.Content:
          this.editForm.wikiContent.content.settings.isVisible = value;
          break;
        case WikiJsonTextEnum.Body:
          this.editForm.wikiContent.body[index].settings.isVisible = value;
          break;
        case WikiJsonTextEnum.Participants:
          this.editForm.wikiContent.participants.settings.isVisible = value;
          break;
        default:
          break;
      }
    },
    setParticipants(users: UserEntity[]): void {
      let participants = users.map((user) => ({
        id: user.id,
        fullName: user.fullName,
        mainAlias: user.mainAlias,
        isActive: false,
        image: user.avatar,
        badges: user.badges,
      }));
      participants = participants.filter((user) => !this.editForm.participantsIds?.includes(user.id));
      this.editForm.wikiContent.participants.users?.push(...participants);

      const ids = participants.map((user) => user.id);

      this.editForm.participantsIds = [...this.editForm.participantsIds, ...ids];
    },
    removeParticipant(id: number): void {
      this.editForm.wikiContent.participants.users = this.editForm.wikiContent.participants.users?.filter(
        (user) => user.id !== id
      );
      this.editForm.participantsIds = this.editForm.participantsIds?.filter((userId) => userId !== id);
    },

    setMessageFromAi(name: string, content: string): void {
      this.messageFromAi = { name, content };
    },
    resetWikiFromAi(): void {
      this.messageFromAi = null;
    },
    setHistoricalWiki(historical: WikiHistoricalModel): boolean {
      if (!this.existingWiki) {
        console.error('No existing wiki found');
        return false;
      }

      const fullModelFromHistorical = {
        // Historical data
        id: this.existingWiki.id,
        name: historical.name,
        editedBy: historical.editedBy,
        editedAt: historical.editedAt,
        changeType: historical.changeType,
        isMajor: historical.isMajor,
        wikiText: historical.wikiText ?? '',
        wikiContent: historical.wikiContent ?? cloneDeep(defaultAdvancedWikiModel),
        // version: historical.version,

        // Existing data
        version: this.existingWiki.version,
        createdAt: this.existingWiki.createdAt,
        createdBy: this.existingWiki.createdBy,
        group: this.existingWiki.group,
        bodyHtml: this.existingWiki.bodyHtml,
        tempWikiId: this.existingWiki.tempWikiId,
        tempWikiText: this.existingWiki.tempWikiText,
        tempWikiContent: this.existingWiki.tempWikiContent,
        tags: this.existingWiki.tags,
        access: this.existingWiki.access,
        isOfficial: this.existingWiki.isOfficial,
        parentFolderId: this.existingWiki.parentFolderId,
        isFollowed: this.existingWiki.isFollowed,
      };

      this.historicalWiki = fullModelFromHistorical;

      this.logDifferences(this.historicalWiki, this.existingWiki);

      return true;
    },

    logDifferences(a: WikiModel, b: WikiModel): void {
      const objectDiff = (a: WikiModel, b: WikiModel) => fromPairs(differenceWith(toPairs(a), toPairs(b), isEqual));

      if (!a || !b) {
        console.error('No wiki data found');
      } else {
        const diff = objectDiff(b, a);
        console.log('[VERBOSE] diff', diff); //! DEBUG
      }
    },

    // API Calls
    async getWikiById(id: number): Promise<WikiModel | false> {
      this.isLoading = true;
      try {
        const response = await $api.wiki.getWikiById(id);

        if (response.statusCode === 200) {
          const model = response as ResponseWikiModel;
          model.data.bodyHtml = await useRichTextEditor().preprocessBody(model.data.wikiText);
          this.existingWiki = model.data;
          return model.data;
        }

        this.existingWiki = null;
        return false;
      } catch (e) {
        console.error(e);
        this.existingWiki = null;
        return false;
      } finally {
        this.isLoading = false;
      }
    },

    async create(payload: CreateWikiPayload): Promise<number | false> {
      this.isLoading = true;
      try {
        if (payload.text) {
          payload.text = useRichTextEditor().preSubmit(payload.text);
        }

        const response = await $api.wiki.create(payload);
        if (response.statusCode === 200) {
          const { data } = response as ResponseWikiCreateModel;
          this.newId = data;
          return data;
        }

        return false;
      } catch (e) {
        console.error(e);
        return false;
      } finally {
        this.isLoading = false;
      }
    },

    async update(payload: UpdateWikiPayload): Promise<boolean> {
      this.isLoading = true;
      try {
        if (payload.text) {
          payload.text = useRichTextEditor().preSubmit(payload.text);
        }

        const response = await $api.wiki.update(payload);

        return response.statusCode === 200;
      } catch (e) {
        console.error('Failed to update wiki', e);
        return false;
      } finally {
        this.isLoading = false;
      }
    },

    async download(id: number, documentExtension: DocumentExtensionEnum): Promise<Blob | ResponseErrorModel> {
      try {
        return await $api.wiki.download(id, documentExtension);
      } catch (e) {
        console.error('Failed to download wiki', e);
        return e as ResponseErrorModel;
      }
    },

    async markOfficial(id: number): Promise<boolean> {
      try {
        const docStore = useDocStore();
        const response = await $api.wiki.markAsOfficial(id);

        if (response.statusCode === 200) {
          if (this.existingWiki) {
            this.existingWiki.isOfficial = !this.existingWiki.isOfficial;
          }
          docStore.updateDocsAfterMarkOfficial(
            docStore.browserMode,
            docStore.sortingType,
            id,
            docStore.activeGroup?.id,
            docStore.activeFolder?.id
          );
        }

        return response.statusCode === 200;
      } catch (e) {
        console.error('Failed to make wiki official', e);
        return false;
      }
    },

    async delete(id: number): Promise<boolean> {
      try {
        const response = await $api.wiki.delete(id);
        return response.statusCode === 200;
      } catch (e) {
        console.error('Failed to delete wiki', e);
        return false;
      }
    },

    async deleteWithReplace(
      id: number,
      relationWikiId: number | null,
      relationFileId: number | null
    ): Promise<boolean> {
      try {
        const response = await $api.wiki.deleteWithReplace(id, relationWikiId, relationFileId);

        return response.statusCode === 200;
      } catch (e) {
        console.error('Failed to delete wiki and replace', e);
        return false;
      }
    },

    //* Relations
    async getRelations(id: number): Promise<boolean> {
      try {
        const response = await $api.wiki.getRelations(id);

        if (response.statusCode === 200) {
          const { data } = response as ResponseWikiRelationsModel;
          this.relations.data = data.data;
          this.relations.loadMoreUrl = data.loadMoreUrl;

          return true;
        } else {
          return false;
        }
      } catch (e) {
        console.error('Failed to show relations', e);
        return false;
      }
    },

    //TODO: NOTE: Add Related to Wiki
    async addRelation(id: number, relationFileId: number | null, relationWikiId: number | null): Promise<boolean> {
      try {
        const response = await $api.wiki.addRelation(id, relationFileId, relationWikiId);

        return response.statusCode === 200;
      } catch (e) {
        console.error('Failed to add relation', e);
        return false;
      }
    },

    //TODO: NOTE: Remove Related from Wiki
    async removeRelation(id: number, relationFileId: number | null, relationWikiId: number | null): Promise<boolean> {
      try {
        const response = await $api.wiki.removeRelation(id, relationFileId, relationWikiId);

        return response.statusCode === 200;
      } catch (e) {
        console.error('Failed to remove relation', e);
        return false;
      }
    },

    //* Followers
    async getFollowers(id: number): Promise<boolean> {
      try {
        const response = await $api.wiki.getFollowers(id);

        if (response.statusCode === 200) {
          const model = response as ResponseWikiFollowersModel;
          this.wikiFollowers = (model.data as UserEntity[]) || [];

          return true;
        } else {
          return false;
        }
      } catch (e) {
        console.error('Failed to get followers', e);
        return false;
      }
    },
    async follow(id: number): Promise<boolean> {
      try {
        const docStore = useDocStore();
        const response = await $api.wiki.follow(id);

        if (response.statusCode === 200) {
          if (this.existingWiki) {
            this.existingWiki.isFollowed = !this.existingWiki.isFollowed;
          }
          docStore.updateDocsAfterFollow(
            docStore.browserMode,
            docStore.sortingType,
            id,
            docStore.activeGroup?.id,
            docStore.activeFolder?.id
          );
        }

        return response.statusCode === 200;
      } catch (e) {
        console.error('Failed to follow wiki', e);
        return false;
      }
    },
    async unfollow(id: number): Promise<boolean> {
      try {
        const docStore = useDocStore();
        const response = await $api.wiki.unfollow(id);

        if (response.statusCode === 200) {
          if (this.existingWiki) {
            this.existingWiki.isFollowed = !this.existingWiki.isFollowed;
          }
          docStore.updateDocsAfterFollow(
            docStore.browserMode,
            docStore.sortingType,
            id,
            docStore.activeGroup?.id,
            docStore.activeFolder?.id
          );
        }

        return response.statusCode === 200;
      } catch (e) {
        console.error('Failed to unfollow wiki', e);
        return false;
      }
    },

    //* History
    async getHistory(id: number): Promise<boolean> {
      try {
        const response = await $api.wiki.getHistory(id);

        if (response.statusCode === 200) {
          const model = response as ResponseWikiHistoryModel;
          this.history = model.data;

          return true;
        } else {
          this.history = [];

          return false;
        }
      } catch (e) {
        console.error('Failed to get available wiki historical versions', e);
        this.history = [];

        return false;
      }
    },

    async getHistoricalWiki(id: number): Promise<WikiHistoricalModel | null> {
      try {
        const response = await $api.wiki.getHistoryById(id);

        if (response.statusCode === 200) {
          const model = response as ResponseWikiHistoryByIdModel;

          //?: Do we need to preprocess body here?
          // model.data.text = await useRichText().preprocessBody(
          //   model.data.text
          // );

          //TODO: Remove this after backend fix - #787
          const transformedData = mapFromObsoleteWikiHistoricalModelFields(model.data);

          const found = this.historicalWikis.find((wiki) => wiki.id === transformedData.id);
          if (!found) {
            this.historicalWikis.push(transformedData);
          }
          return transformedData;
        } else {
          return null;
        }
      } catch (e) {
        console.error('Failed to get available wiki historical versions', e);
        return null;
      }
    },

    async getHistoricalWikiByDate(
      id: number,
      date: string,
      majorFilter: WikiMajorFilterEnum
    ): Promise<WikiHistoricalModel | null> {
      try {
        const response = await $api.wiki.getHistoryByDate(id, date, majorFilter);

        if (response.statusCode === 200) {
          const model = response as ResponseWikiHistoryByIdModel;
          //?: Do we need to preprocess body here?
          // model.data.text = await useRichText().preprocessBody(
          //   model.data.text
          // );

          //TODO: Remove this after backend fix - #787
          const transformedData = mapFromObsoleteWikiHistoricalModelFields(model.data);

          const found = this.historicalWikis.find((wiki) => wiki.id === transformedData.id);
          if (!found) {
            this.historicalWikis.push(transformedData);
          }

          return transformedData;
        } else {
          return null;
        }
      } catch (e) {
        console.error('Failed to get available wiki historical versions', e);
        return null;
      }
    },

    //TODO: NOTE: Update Wiki Revision
    async updateHistoricalWiki(payload: UpdateWikiPayload): Promise<boolean> {
      try {
        const response = await $api.wiki.updateHistoricalWiki(payload);

        return response.statusCode === 200;
      } catch (e) {
        console.error('Failed to update wiki revision', e);
        return false;
      }
    },

    async rollback(id: number, historyId: number): Promise<WikiHistoryModel | null> {
      try {
        const response = await $api.wiki.rollback(id, historyId);

        if (response.statusCode === 200) {
          const model = response as ResponseWikiHistoryByIdModel;
          return model.data;
        } else {
          return null;
        }
      } catch (e) {
        console.error('Failed to rollback wiki', e);
        return null;
      }
    },

    //* Drafts
    async getCurrentDraft(): Promise<boolean> {
      try {
        const response = await $api.wiki.getCurrentDraft();

        if (response.statusCode === 200) {
          const model = response as ResponseWikiDraftModel;
          if (model?.data?.text) {
            model.data.text = await useRichTextEditor().preprocessBody(model?.data?.text);
          }
          this.draftWiki = model.data;

          return true;
        } else {
          this.draftWiki = null;

          return false;
        }
      } catch (e) {
        console.error('Failed to get current draft', e);
        this.draftWiki = null;
        return false;
      }
    },

    async getDraftById(id: number): Promise<boolean> {
      try {
        const response = await $api.wiki.getDraftById(id);

        if (response.statusCode === 200) {
          const model = response as ResponseWikiDraftModel;
          if (model.data) {
            if (model.data.text) {
              model.data.text = await useRichTextEditor().preprocessBody(model.data.text);
            }
            this.draftWiki = model.data;

            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } catch (e) {
        console.error('Failed to get template by id', e);
        return false;
      }
    },

    async updateCurrentDraft(payload: UpdateWikiDraftPayload): Promise<boolean> {
      this.autoUpdateStatus = AutoUpdateStatusEnum.Processing;

      try {
        if (payload.text) {
          payload.text = useRichTextEditor().preSubmit(payload.text);
        }
        const response = await $api.wiki.updateCurrentDraft(payload);

        if (response.statusCode === 200 && (response as ResponseWikiDraftUpdateModel).data !== null) {
          const { data } = response as ResponseWikiDraftUpdateModel;

          if (data.tempWikiId) {
            this.autoUpdateStatus = AutoUpdateStatusEnum.Done;
            await this.getDraftById(data.tempWikiId);
          }

          return true;
        } else {
          this.autoUpdateStatus = AutoUpdateStatusEnum.Fail;
          this.draftWiki = null;
          return false;
        }
      } catch (e) {
        console.error(e);
        this.autoUpdateStatus = AutoUpdateStatusEnum.Fail;
        this.draftWiki = null;
        return false;
      }
    },

    async deleteDraftById(id: number): Promise<boolean> {
      try {
        const response = await $api.wiki.deleteDraftById(id);

        if (response.statusCode === 200) {
          this.draftWiki = null;
          //!: temporary solution for deleting draft of existing wiki or new wiki
          if (this.existingWiki?.id) {
            await this.getWikiById(this.existingWiki?.id);
          } else {
            this.resetEditForm();
          }
          this.isDraftDeleted = true;
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);

        return false;
      }
    },

    //#region Templates

    //NOTE: Get Wiki Template
    async templateById(id: number): Promise<WikiTemplateModel | undefined> {
      const response = await $api.wiki.getTemplateById(id);

      if (response.statusCode === 200) {
        const model = response as ResponseWikiTemplateModel;
        const index = this.templates.data.findIndex((t) => t.id === model.data?.id);
        if (~index) {
          this.templates.data[index] = model.data;
        }
        return model.data;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        console.error(error.errorMessages);
      }

      return undefined;
    },

    //NOTE: Get Wiki Templates
    async allTemplates(): Promise<boolean> {
      try {
        const response = await $api.wiki.getTemplates();

        if (response.statusCode === 200) {
          const model = response as ResponseWikiTemplatesModel;
          this.$patch((state) => {
            state.templates.data = cloneDeep(model.data);
            state.templates.loadMoreUrl = model.loadMoreUrl;
          });
          return true;
        }

        if (response.statusCode !== 200) {
          const error = response as ResponseErrorModel;
          console.error(error.errorMessages);
        }

        return false;
      } catch (e) {
        console.error('Failed to get wiki templates', e);
        return false;
      }
    },

    //NOTE: Get Wiki Templates Load More
    async templatesLoadMore(loadMoreUrl: string): Promise<boolean> {
      const response = await $api.wiki.getTemplatesLoadMore(loadMoreUrl);

      if (response.statusCode === 200) {
        const model = response as ResponseWikiTemplatesModel;
        this.templates.data = cloneDeep(mergeTemplatesById(this.templates.data, model.data));
        this.templates.loadMoreUrl = model.loadMoreUrl;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        console.error(error.errorMessages);
      }

      return false;
    },

    //NOTE: Create Wiki Template
    async createTemplate(templateData: CreateWikiTemplateModel): Promise<boolean> {
      const response = await $api.wiki.createTemplate(templateData);

      if (response.statusCode === 200) {
        const model = response as ResponseWikiTemplateModel;
        this.templates.data.push(model.data);
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        console.error(error.errorMessages);
      }

      return false;
    },

    //NOTE: Update Wiki Template
    async updateTemplateById(templateData: SaveWikiTemplateModel): Promise<boolean> {
      const response = await $api.wiki.updateTemplateById(templateData);

      if (response.statusCode === 200) {
        const model = response as ResponseWikiTemplateModel;
        const index = this.templates.data.findIndex((t) => t.id === model.data?.id);
        if (~index) {
          this.templates.data[index] = model.data;
        }
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        console.error(error.errorMessages);
      }

      return false;
    },

    //NOTE: Delete Wiki Template
    async deleteTemplateById(id: number): Promise<boolean> {
      const response = await $api.wiki.deleteTemplateById(id);

      if (response.statusCode === 200) {
        remove(this.templates.data, { id });
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        console.error(error.errorMessages);
      }

      return false;
    },

    //* Tags
    async addTag(id: number, tags: TopicEntity[]): Promise<boolean> {
      try {
        const response = await $api.wiki.addTag(
          id,
          tags.map(({ title }) => title)
        );

        return response.statusCode === 200;
      } catch (e) {
        console.error(e);

        return false;
      }
    },

    async removeTag(id: number, tagId: number): Promise<boolean> {
      try {
        const response = await $api.wiki.removeTag(id, tagId);

        return response.statusCode === 200;
      } catch (e) {
        console.error(e);

        return false;
      }
    },

    //* Lock
    async lockEdit(id: number): Promise<boolean> {
      try {
        const response = await $api.wiki.lockEdit(id);
        if (response.statusCode === 200) {
          const model = response as ResponseLockModel;
          return [WikiLockStatusEnum.NoLock, WikiLockStatusEnum.Offline, WikiLockStatusEnum.Updated].includes(
            model.data.result
          );
        }
        return false;
      } catch (e) {
        console.error(e);
        return false;
      }
    },

    async unlockEdit(id: number): Promise<boolean> {
      try {
        const response = await $api.wiki.unlockEdit(id);
        if (response.statusCode === 200) {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
        return false;
      }
    },

    //* Contributions
    //TODO: NOTE: Get Wikis by User (Contributions)
    // async getContributionsByUserId(userId: number): Promise<boolean>

    setMentionedUserId(ids: number[]) {
      if (!this.editForm.mentionedUserIds) {
        this.editForm.mentionedUserIds = [...ids];
      } else {
        this.editForm.mentionedUserIds = [...this.editForm.mentionedUserIds, ...ids];
      }
    },
    async autocomplete(searchText: string): Promise<void> {
      const response = await $api.wiki.autocomplete(searchText);

      if (response.statusCode === 200) {
        const model = response as ResponseWikisModel;

        this.data = mergeById(this.data, model.data);

        this.wikisIds.search.ids = model.data.map((n) => n.id);
        this.wikisIds.search.loadMoreUrl = model.loadMoreUrl;

        return;
      }

      return;
    },
  },
  persist: true,
});

const mergeById = (a: WikiModel[], b: WikiModel[]) => {
  return unionBy(a, b, 'id').map((obj) => {
    const match = find(b, { id: obj.id });
    return match ? Object.assign({}, obj, match) : obj;
  });
};

const mergeTemplatesById = (a: WikiTemplateModel[], b: WikiTemplateModel[]) => {
  return unionBy(a, b, 'id').map((obj) => {
    const match = find(b, { id: obj.id });
    return match ? Object.assign({}, obj, match) : obj;
  });
};

const mapFromObsoleteWikiHistoricalModelFields = (model: WikiHistoricalModel): WikiHistoricalModel => {
  return {
    ...model,
    wikiText: model.text || model.wikiText,
    wikiContent: model.content || model.wikiContent,
    editedAt: model.modifyDate || model.editedAt,
  };
};
