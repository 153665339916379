interface IImages {
  storeImage: (key: string, blob: Blob) => void;
  giveImage: (key: string) => Blob | null;
  getStoredImagesInfo: () => { count: number; size: number };
  clearImages: () => void;
  updateImageAfterCropping: (canvas: any, image: Blob) => Promise<File>;
}

let instance: IImages | null = null;

export function useImages(): IImages {
  if (instance) {
    return instance;
  }

  const images: Record<string, Blob> = {};

  const storeImage = (key: string, blob: Blob): void => {
    images[key] = blob;
  };

  const giveImage = (key: string): Blob | null => {
    return images[key] || null;
  };

  const clearImages = (): void => {
    Object.keys(images).forEach((key) => {
      delete images[key];
    });
  };

  const getStoredImagesInfo = (): { count: number; size: number } => {
    const keys = Object.keys(images);
    const count = keys.length;
    const size = keys.reduce((total, key) => total + images[key].size, 0);
    return { count, size };
  };

  const updateImageAfterCropping = (canvas: any, image: Blob): Promise<File> => {
    return new Promise((resolve, reject) => {
      const imageType = image.type;
      const imageExtension = imageType.split('/')[1];

      canvas.toBlob((blob: Blob | null) => {
        if (blob) {
          const filename = `image.${imageExtension}`;
          const changedImage = new File([blob], filename, { type: imageType });
          resolve(changedImage);
        } else {
          reject(new Error('Failed to create Blob from canvas'));
        }
      }, imageType);
    });
  };

  instance = {
    storeImage,
    giveImage,
    clearImages,
    getStoredImagesInfo,
    updateImageAfterCropping,
  };

  return instance;
}
