<template>
  <div
    :id="videoId"
    ref="videoRef"
    class="video-player"
    :style="`height:${size ? size.height : 144}px;width:${size ? size.width : 256}px`"
  >
    <div class="video-wrapper" @click.stop="onPlay">
      <ion-img v-if="data.thumbnail" :src="data.thumbnail" :title="name" :alt="name" />
      <ion-icon v-if="!data.isLoading" :icon="getIcon()" />
      <div v-if="data.isLoading" class="spinner-wrapper">
        <icons-provider
          class="spinner"
          :icon-props="{
            width: '22',
            height: '22',
            fill: 'var(--ion-color-light)',
          }"
          :name="AppIconsEnum.CircleAnim"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { IonIcon, IonImg } from '@ionic/vue';
import { Guid } from 'guid-typescript';
import { playCircleOutline, timeOutline, alertCircleOutline } from 'ionicons/icons';
import type { PropType } from 'vue';
import { onMounted, onUnmounted, reactive, ref } from 'vue';

import { IconsProvider } from '@/components';
import { AppIconsEnum } from '@/enums';
import type { VideoPlayerHook } from '@/helpers';
import { filesHybrid, getExtensionFromType, isBlob, useVideoPlayer, useErrors } from '@/helpers';
import { useI18n } from '@/i18n';
import { $api } from '@/services';
import type { FilePickModel, ResponseErrorModel } from '@/types';

// Props
const props = defineProps({
  id: {
    type: String,
    required: true,
    default: () => '',
  },
  url: {
    type: String,
    required: true,
    default: () => null,
  },
  mimeType: {
    type: String,
    required: true,
    default: () => '',
  },
  name: {
    type: String,
    required: true,
    default: () => '',
  },
  image: {
    type: String,
    required: true,
    default: () => null,
  },
  size: {
    type: Object as PropType<{ width: number; height: number }>,
    default: () => undefined,
  },
});

// Icons
const icons = {
  play: playCircleOutline,
  time: timeOutline,
  alert: alertCircleOutline,
};

// Helpers
const { t } = useI18n();
const { handleError } = useErrors();
const vpHook: VideoPlayerHook = useVideoPlayer();
const videoId = Guid.create().toString();
const ext = getExtensionFromType(props.mimeType);

// Refs
const videoRef = ref<HTMLDivElement | null>(null);

const data = reactive({
  isLoadVideo: false,
  isPlayVideo: false,
  isLoading: false,
  thumbnail: '',
  status: 0,
});
if (props.image) {
  $api.file.media(props.image).then((file: Blob | ResponseErrorModel) => {
    if (isBlob(file)) {
      const urlCreator = window.URL || window.webkitURL;
      data.thumbnail = urlCreator.createObjectURL(file as Blob);
    }
  });
}

// Actions
const getIcon = () => {
  if (data.status === 0) {
    return icons.play;
  } else if (data.status === 202) {
    return icons.time;
  } else {
    return icons.alert;
  }
};

const onPlay = async () => {
  const index = filesHybrid.files.value.findIndex((f: FilePickModel) => f.internal === props.id);
  if (~index) {
    const url = vpHook.isNative() ? filesHybrid.files.value[index].path : filesHybrid.files.value[index].media;
    if (url) {
      await vpHook.playInApp(url, props.id, `[id='${videoId}']`, props.size?.width, props.size?.height);
      data.isPlayVideo = true;
      return;
    }
  }

  const preventPlay = async (media?: ResponseErrorModel) => {
    data.isLoading = false;
    data.isLoadVideo = false;
    if (media) {
      await handleErrorResponse(media);
    }
  };

  if (!data.isLoading && !data.isLoadVideo) {
    data.isLoading = true;

    const media = await $api.file.media(props.url);
    if (!isBlob(media)) {
      await preventPlay(media as ResponseErrorModel);
    } else {
      const mediaFile = media as Blob;
      if (mediaFile?.size === 0) {
        await preventPlay();
      } else {
        await proceedPlay(mediaFile);
      }
    }
  }
};

const handleErrorResponse = async (responseError: ResponseErrorModel) => {
  switch (responseError.statusCode) {
    case 202:
      data.status = 202;
      handleError(true, undefined, t('files.isProcessing'));
      break;
    case 404:
      data.status = 404;
      handleError(true, undefined, t('files.notFound'));
      break;
    case 500:
      data.status = 500;
      handleError(true, undefined, t('files.processingError'));
      break;
    default:
      break;
  }
};

const proceedPlay = async (media: Blob) => {
  let fileUrl: string;

  if (media?.size === 0) return;

  if (vpHook.isNative()) {
    const savedFile = await filesHybrid.saveFile(media as Blob, props.id, props.name, ext, props.mimeType);
    if (savedFile !== undefined) {
      fileUrl = savedFile;
    } else {
      return;
    }
  } else {
    const urlCreator = window.URL || window.webkitURL;
    fileUrl = urlCreator.createObjectURL(media as Blob);
  }

  data.isLoading = false;
  data.isLoadVideo = true;

  await vpHook.playInApp(fileUrl, props.id, `[id='${videoId}']`, props.size?.width, props.size?.height);
  data.isPlayVideo = true;
};

// Lifecycle
onMounted(async () => {
  const videoContainer: HTMLDivElement = document.createElement('div');
  videoContainer.id = `${props.id}`;
  videoRef.value?.appendChild(videoContainer);
});

onUnmounted(async () => {
  if (data.isPlayVideo) {
    await vpHook.stopAllPlayers();
  }
});
</script>

<style scoped lang="scss">
.video-player {
  position: relative;
}
.video-wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #0d0d0d;
  font-size: 40px;
  color: white;
  overflow: hidden;
  border-radius: app-radius(md);
}
.video-wrapper ion-img {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.video-wrapper ion-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: var(--ion-color-intra);
  border-radius: 50%;
  padding: 0.5rem;
  color: var(--ion-color-custom);
}
.spinner-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(var(--ion-color-dark-rgb), 0.4);
  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    border-radius: 50%;
  }
}
</style>
