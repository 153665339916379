<template>
  <div class="input-container-custom">
    <ion-input
      ref="input"
      mode="md"
      :type="type"
      :autocapitalize="autocapitalize"
      :autocomplete="autocomplete"
      :value="value"
      :maxlength="maxLength"
      label-placement="floating"
      color="medium"
      fill="outline"
      :error-text="errorText[0]"
      :class="[isError ? ['ion-invalid', 'ion-touched'] : '', borderRadius]"
      :label="label"
      :counter="counter"
      :disabled="disabled"
      @ionInput="handleInputChange"
      @keydown.enter="keyUp"
    >
      <slot />
    </ion-input>
  </div>
</template>

<script lang="ts" setup>
import type { AutocompleteTypes, TextFieldTypes } from '@ionic/core';
import { IonInput } from '@ionic/vue';
import type { PropType, ComputedRef } from 'vue';
import { onMounted, ref, computed } from 'vue';

import { BreakpointsEnum } from '@/enums';
import { keyUpSend } from '@/helpers';

/** This is a re-usable vue component for input field
 *  @example
 *  <app-input-outline
 *    v-model:value="form.title"
 *    type="text"
 *    label="'Label'"
 *    :error-text="titleErrors"
 *    :max-length="44"
 *    :counter="true"
 *    :disabled="false
 *    class="mt-lg"
 *  />
 */

/**
 * Props definition for the AppInputOutline component
 *
 * @property {string} [value=''] - The value of the input
 * @property {string} [label=''] - The label of the input
 * @property {string} [errorText=''] - The error text of the input
 * @property {string} [autocapitalize='off'] - The autocapitalization of the input
 * @property {string} [autocomplete='off'] - The autocomplete of the input
 * @property {string} [type='text'] - The type of the input
 * @property {number} [maxLength=undefined] - The maximum length of the input
 * @property {boolean} [counter=false] - If the input should apply counter
 * @property {boolean} [applyAutoFocus=false] - If the input should apply auto focus
 * @property {boolean} [disabled=false] - If the input should be disabled
 */

const props = defineProps({
  value: {
    type: [String, Number],
    default: () => '',
  },
  errorText: {
    type: Array as PropType<string[]>,
    require: false,
    default: () => [],
  },
  label: {
    type: String,
    default: () => '',
  },
  autocapitalize: {
    type: String as PropType<'off' | 'none' | 'on' | 'sentences' | 'words' | 'characters'>,
    default: () => 'off',
  },
  autocomplete: {
    type: String as PropType<AutocompleteTypes>,
    default: () => 'off',
  },
  type: {
    type: String as PropType<TextFieldTypes>,
    default: () => 'text',
  },
  maxLength: {
    type: Number,
    default: undefined,
  },
  counter: {
    type: Boolean,
    default: () => false,
  },
  applyAutoFocus: {
    type: Boolean,
    default: () => false,
  },
  //NOTE: This is a temporary solution to change the border radius of the input, will be removed when the new design is implemented
  borderRadius: {
    type: String as PropType<BreakpointsEnum>,
    default: () => BreakpointsEnum.MD,
  },
  disabled: {
    type: Boolean,
    default: () => false,
  },
});

const isError: ComputedRef<boolean> = computed(() => {
  return props.errorText.length > 0;
});

const input = ref<any>(null);
const keyUpAction = ref<boolean>(false);

const handleInputChange = (event: Event) => {
  let value = '';
  value = (event.target as HTMLInputElement).value;
  emit('update:value', value);
};

const emit = defineEmits(['update:value', 'update:key-up', 'onFocus', 'onBlur']);

const keyUp = (event: KeyboardEvent | MouseEvent) => {
  keyUpSend(event) ? (keyUpAction.value = true) : (keyUpAction.value = false);
  emit('update:key-up', keyUpAction.value);
};

onMounted(async () => {
  if (input.value && props.applyAutoFocus) {
    setTimeout(() => input.value.$el.setFocus(), 0);
  }
});
</script>

<style scoped lang="scss">
.input-container-custom {
  padding-top: 7.125px;
  ion-input {
    --padding-end: #{app-padding(md)} !important;
    &.Md {
      --border-radius: #{app-radius(md)} !important;
    }
    &.Sm {
      --border-radius: #{app-radius(sm)} !important;
    }
  }
}
</style>
