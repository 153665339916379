<template>
  <template v-if="!withControls">
    <div class="empty">
      <ion-text>
        {{ noDataText }}
      </ion-text>
    </div>
  </template>

  <template v-else>
    <div class="not-found">
      <div class="not-found--block">
        <icons-provider
          :icon-props="{
            width: dimension.width,
            height: dimension.height,
            fill: color,
          }"
          name="communex-small"
          class="icon"
        />

        <ion-text class="animate__animated animate__slideInLeft">
          {{ noDataText }}
        </ion-text>

        <div class="animate__animated animate__slideInLeft">
          <ion-button mode="md" @click="reloadAndResetCache()">
            {{ $t('notFoundPage.reloadButton') }}
            <!--TODO: cs / de / es / fr / ru -->
          </ion-button>

          <ion-button mode="md" @click="() => router.go(-1)">
            {{ $t('notFoundPage.goBackButton') }}
          </ion-button>
        </div>
      </div>
    </div>
  </template>
</template>

<script lang="ts" setup>
import { IonText, IonButton } from '@ionic/vue';
import { computed } from 'vue';
import type { ComputedRef } from 'vue';
import { useRouter } from 'vue-router';

import { IconsProvider } from '@/components';
import { ThemeAppEnum } from '@/enums';
import { reloadAndResetCache } from '@/helpers';
import { useI18n } from '@/i18n';
import { useAppStore, useNetworkStore } from '@/store';

// Props
const props = defineProps({
  text: {
    type: String || null,
    default: () => null,
  },
  withControls: {
    type: Boolean,
    default: () => false,
  },
});

// Router
const router = useRouter();

// Store
const appStore = useAppStore();
const networkStore = useNetworkStore();

// Helpers
const { t } = useI18n();

// Computed
const color: ComputedRef<string> = computed(() => {
  if (networkStore.settings?.headBackgroundColor) {
    return networkStore.settings.headBackgroundColor;
  } else {
    return appStore.theme === ThemeAppEnum.Dark ? '#ffffff' : '#121212';
  }
});

const dimension: ComputedRef<{ width: string; height: string }> = computed(() => {
  return {
    width: window.innerWidth * 0.15 + 'px',
    height: window.innerWidth * 0.15 + 'px',
  };
});

const noDataText: ComputedRef<string> = computed(() => {
  if (props.text !== null) {
    return props.text;
  }
  return t('noData');
});

// https://animate.style/
// node_modules/animate.css/animate.css
</script>

<style scoped lang="scss">
.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--ion-color-light-background-contrast);
  border-radius: app-radius(md);
}
.empty ion-text {
  padding: app-padding(lg);
  color: var(--ion-color-medium);
  text-align: center;
}

.not-found {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: start;
  justify-content: center;
  margin-top: 10vh;
}
.not-found--block {
  display: flex;
  flex-direction: column;
  align-items: center;

  ion-text {
    margin-top: 2vh;
    text-align: center;
    font-size: 1.5rem;
    max-height: 20vh;
    max-width: 60vw;
  }

  ion-button {
    margin-top: 5vh;
    width: 15vw;
    height: 5vh;
    font-size: 1.2rem;
    --box-shadow: none;
    --border-radius: #{app-radius(sm)};
    --border-color: var(--ion-color-light-background);
    --border-style: solid;
    --border-width: 1px;
    --background: var(--ion-color-intra);
    margin-inline: 0;
    white-space: normal;
    color: var(--ion-color-custom);

    .icon {
      margin-right: 0.5rem;
    }
  }
}
</style>
