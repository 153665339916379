import type { OverlayEventDetail } from '@ionic/core';
import { actionSheetController } from '@ionic/vue';
import {
  pencilOutline,
  copyOutline,
  eyeOutline,
  calendarOutline,
  timerOutline,
  arrowUndoOutline,
} from 'ionicons/icons';
import { map } from 'lodash';

import { useTaskManagement } from './useTaskManagementHelper';

import type {
  PostTypeActionEnum,
  AppImageChangeMenuEnum,
  TaskManagementTaskModalMenuCategoryEnum,
  TaskManagementTaskModalMenuItemActionEnum,
  FileMenuActionEnum,
  FeedFlagEnum,
  FeedTypeEnum,
  PostMenuActionEnum,
  MessageActionEnum,
  WikiMenuActionEnum,
  CommentActionEnum,
  PostUploadFileEnum,
  DocsMenuActionEnum,
} from '@/enums';
import {
  PostMenuOptionsFlagEnum,
  CalendarCellActionEnum,
  CalendarViewModeEnum,
  EventCalendarSourceEnum,
  PlannedPostActionEnum,
  PostTextActionEnum,
  DocumentTypeEnum,
} from '@/enums';
import {
  calendarShowBackBtn,
  calendarShowEvents,
  docBrowserMenuItems,
  getCommentContextMenuItems,
  getMessengerContextMenuItems,
  getPostContextMenuItems,
  getPostUploadFileMenuItems,
  useFileActions,
  useWiki,
} from '@/helpers';
import { useI18n } from '@/i18n';
import type { AppMenuButtonsModel, DocEntity, PostEntity, MessageEntity, WikiModel } from '@/types';

export const calendarCellMenuSheet = async (
  activeView: CalendarViewModeEnum,
  isSmall: boolean
): Promise<OverlayEventDetail<CalendarCellActionEnum | undefined>> => {
  const { t } = useI18n();
  const menuItems = [
    {
      title: t('feed.event.menu.newEvent'),
      value: CalendarCellActionEnum.CreateEvent,
      icon: pencilOutline,
    },
    {
      title: t('feed.event.menu.showEvents'),
      value: CalendarCellActionEnum.ShowEvents,
      icon: eyeOutline,
      disabled: !calendarShowEvents(isSmall, activeView),
    },
    {
      title: t('feed.event.menu.toDate'),
      value: CalendarCellActionEnum.ToDate,
      icon: calendarOutline,
      disabled: activeView === CalendarViewModeEnum.Day,
    },
    {
      title: t('back'),
      value: CalendarCellActionEnum.Back,
      icon: arrowUndoOutline,
      disabled: !calendarShowBackBtn(isSmall, activeView),
    },
  ].filter(({ disabled }) => !disabled);
  const actionSheet = await actionSheetController.create({
    buttons: map(menuItems, (obj) => {
      const { title, icon, value } = obj;
      return { text: title, data: value, icon: icon };
    }),
  });
  await actionSheet.present();
  return actionSheet.onDidDismiss().then(async (result: OverlayEventDetail<CalendarCellActionEnum | undefined>) => {
    return result;
  });
};

export const calendarSourceSheet = async (): Promise<OverlayEventDetail<EventCalendarSourceEnum | undefined>> => {
  const { t } = useI18n();
  const menuItems = [
    {
      title: t('feed.event.allEvents'),
      value: EventCalendarSourceEnum.All,
    },
    {
      title: t('feed.event.myEvents'),
      value: EventCalendarSourceEnum.My,
    },
    {
      title: t('feed.event.groupEvents'),
      value: EventCalendarSourceEnum.Groups,
    },
  ];
  const actionSheet = await actionSheetController.create({
    buttons: map(menuItems, (obj) => {
      const { title, value } = obj;
      return { text: title, data: value };
    }),
  });
  await actionSheet.present();
  return actionSheet.onDidDismiss().then(async (result: OverlayEventDetail<EventCalendarSourceEnum | undefined>) => {
    return result;
  });
};

export const postTextMenuSheet = async (): Promise<OverlayEventDetail<PostTextActionEnum | undefined>> => {
  const { t } = useI18n();
  const menuItems = [
    {
      icon: copyOutline,
      disabled: false,
      value: PostTextActionEnum.CopyText,
      title: t('appPopoverMenu.copy.title'),
    },
  ].filter(({ disabled }) => !disabled);
  const actionSheet = await actionSheetController.create({
    buttons: map(menuItems, (obj) => {
      const { title, icon, value } = obj;
      return { text: title, data: value, icon: icon };
    }),
  });
  await actionSheet.present();
  return actionSheet.onDidDismiss().then(async (result: OverlayEventDetail<PostTextActionEnum | undefined>) => {
    return result;
  });
};

export const plannedPostMenuSheet = async (): Promise<OverlayEventDetail<PlannedPostActionEnum | undefined>> => {
  const { t } = useI18n();
  const menuItems = [
    {
      icon: timerOutline,
      disabled: false,
      value: PlannedPostActionEnum.SchedulePost,
      title: t('feed.schedulePost'),
    },
  ].filter(({ disabled }) => !disabled);
  const actionSheet = await actionSheetController.create({
    buttons: map(menuItems, (obj) => {
      const { title, icon, value } = obj;
      return { text: title, data: value, icon: icon };
    }),
  });
  await actionSheet.present();
  return actionSheet.onDidDismiss().then(async (result: OverlayEventDetail<PlannedPostActionEnum | undefined>) => {
    return result;
  });
};

export const postUploadFileMenuSheet = async (
  groupId: number | null
): Promise<OverlayEventDetail<PostUploadFileEnum | undefined>> => {
  const menuItems = getPostUploadFileMenuItems(false, groupId);
  const actionSheet = await actionSheetController.create({
    buttons: map(menuItems, (obj) => {
      const { title, icon, value } = obj;
      return { text: title, data: value, icon: icon };
    }),
  });
  await actionSheet.present();
  return actionSheet.onDidDismiss().then(async (result: OverlayEventDetail<PostUploadFileEnum | undefined>) => {
    return result;
  });
};

export const commentContextMenuSheet = async (
  authorId: number,
  currentUserId: number,
  isEditable: boolean
): Promise<OverlayEventDetail<CommentActionEnum | undefined>> => {
  const menuItems = getCommentContextMenuItems(authorId, currentUserId, isEditable);
  const actionSheet = await actionSheetController.create({
    buttons: map(menuItems, (obj) => {
      const { title, icon, value, handler } = obj;
      return { text: title, data: value, icon: icon, handler };
    }),
  });
  await actionSheet.present();
  return actionSheet.onDidDismiss().then(async (result: OverlayEventDetail<CommentActionEnum | undefined>) => {
    return result;
  });
};

export const messengerContextMenuSheet = async (
  ev: Event,
  message: MessageEntity,
  currentId: number | undefined,
  deleteFlag = false
): Promise<OverlayEventDetail<MessageActionEnum | undefined>> => {
  const menuItems = getMessengerContextMenuItems(ev, message, currentId, deleteFlag);
  const actionSheet = await actionSheetController.create({
    buttons: map(menuItems, (obj) => {
      const { title, icon, value } = obj;
      return { text: title, data: value, icon: icon };
    }),
  });
  await actionSheet.present();
  return actionSheet.onDidDismiss().then(async (result: OverlayEventDetail<MessageActionEnum | undefined>) => {
    return result;
  });
};

export const docBrowserContextMenuSheet = async (
  file: DocEntity
): Promise<OverlayEventDetail<FileMenuActionEnum | WikiMenuActionEnum | undefined>> => {
  const isWiki: boolean = file.documentType === DocumentTypeEnum.Wiki;
  const menuItems = isWiki
    ? useWiki().getActionsMenuItems(file.data as WikiModel)
    : useFileActions().getDocsFileMenuItems(file);

  if (menuItems.length === 0) {
    return { data: undefined };
  }

  const actionSheet = await actionSheetController.create({
    buttons: map(menuItems, (obj) => {
      // TODO
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { title, svgIconPath, value } = obj;
      return { text: title, data: value, icon: svgIconPath };
    }),
  });
  await actionSheet.present();
  return actionSheet
    .onDidDismiss()
    .then(async (result: OverlayEventDetail<FileMenuActionEnum | WikiMenuActionEnum | undefined>) => {
      return result;
    });
};

export const docBrowserMainMenuSheet = async (
  groupId: number | null
): Promise<OverlayEventDetail<DocsMenuActionEnum | undefined>> => {
  const menuItems = docBrowserMenuItems(false, groupId);
  const actionSheet = await actionSheetController.create({
    buttons: map(menuItems, (obj) => {
      const { title, icon, value } = obj;
      return {
        text: title,
        data: value,
        icon: icon,
        cssClass: 'doc-browser-menu-sheet-buttons',
      };
    }),
  });
  await actionSheet.present();
  return actionSheet.onDidDismiss().then(async (result: OverlayEventDetail<DocsMenuActionEnum | undefined>) => {
    return result;
  });
};

export const postContextMenuSheet = async (
  postData: PostEntity,
  feedFlag: FeedFlagEnum,
  conversationsType?: FeedTypeEnum | string,
  flag: PostMenuOptionsFlagEnum = PostMenuOptionsFlagEnum.All
): Promise<OverlayEventDetail<PostMenuActionEnum | undefined>> => {
  const menuItems = getPostContextMenuItems(postData, feedFlag, conversationsType, flag);
  const actionSheet = await actionSheetController.create({
    buttons: map(menuItems, (obj) => {
      const { title, icon, value, handler } = obj;
      return { text: title, data: value, icon: icon, handler };
    }),
  });
  await actionSheet.present();
  return actionSheet.onDidDismiss().then(async (result: OverlayEventDetail<PostMenuActionEnum | undefined>) => {
    return result;
  });
};

export const postCreateContextMenuSheet = async (
  categories: { value: string; active: boolean; icon: string; title: string }[]
): Promise<OverlayEventDetail<PostTypeActionEnum | undefined>> => {
  const actionSheet = await actionSheetController.create({
    buttons: map(categories, (obj) => {
      const { title, icon, value } = obj;
      return { text: title, data: value, icon: icon };
    }),
  });
  await actionSheet.present();
  return actionSheet.onDidDismiss().then(async (result: OverlayEventDetail<PostTypeActionEnum | undefined>) => {
    return result;
  });
};

export const appImageChangeActionSheet = async (
  actions: AppMenuButtonsModel[]
): Promise<OverlayEventDetail<AppImageChangeMenuEnum | undefined>> => {
  const actionSheet = await actionSheetController.create({
    buttons: map(actions, (obj) => {
      const { action, text, icon, cssClass } = obj;
      return { text: text, data: action, icon: icon, cssClass: cssClass };
    }),
  });
  await actionSheet.present();
  return actionSheet.onDidDismiss().then(async (result: OverlayEventDetail<AppImageChangeMenuEnum | undefined>) => {
    return result;
  });
};

export const taskManagementTaskMainMenuSheet = async (): Promise<
  OverlayEventDetail<TaskManagementTaskModalMenuCategoryEnum | undefined>
> => {
  const taskManagementHelper = useTaskManagement();
  const actionSheet = await actionSheetController.create({
    buttons: map(taskManagementHelper.getTaskMainMenu(), (obj) => {
      const { title, value } = obj;
      return { text: title, data: value };
    }),
  });
  await actionSheet.present();
  return actionSheet
    .onDidDismiss()
    .then(async (result: OverlayEventDetail<TaskManagementTaskModalMenuCategoryEnum | undefined>) => {
      return result;
    });
};

export const taskManagementTaskActionsMenuSheet = async (
  taskIsClosed: boolean,
  taskIsArchived: boolean,
  taskNotify: boolean | null,
  userIsAssigned: boolean,
  projectId: number
): Promise<OverlayEventDetail<TaskManagementTaskModalMenuItemActionEnum | undefined>> => {
  const taskManagementHelper = useTaskManagement();
  const actionSheet = await actionSheetController.create({
    buttons: map(
      taskManagementHelper.getTaskMenuActions(true, taskIsClosed, taskIsArchived, taskNotify, userIsAssigned, projectId)
        .data,
      (obj) => {
        const { title, icon, value } = obj;
        return { text: title, data: value, icon: icon };
      }
    ),
  });
  await actionSheet.present();
  return actionSheet
    .onDidDismiss()
    .then(async (result: OverlayEventDetail<TaskManagementTaskModalMenuItemActionEnum | undefined>) => {
      return result;
    });
};

export const taskManagementTaskFeaturesMenuSheet = async (): Promise<
  OverlayEventDetail<TaskManagementTaskModalMenuItemActionEnum | undefined>
> => {
  const taskManagementHelper = useTaskManagement();
  const actionSheet = await actionSheetController.create({
    buttons: map(taskManagementHelper.getTaskMenuFeatures().data, (obj) => {
      const { title, icon, value } = obj;
      return { text: title, data: value, icon: icon };
    }),
  });
  await actionSheet.present();
  return actionSheet
    .onDidDismiss()
    .then(async (result: OverlayEventDetail<TaskManagementTaskModalMenuItemActionEnum | undefined>) => {
      return result;
    });
};
