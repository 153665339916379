import type { NearestMinutes } from 'date-fns';
import {
  format,
  parseISO,
  addMinutes,
  addSeconds,
  startOfMonth,
  endOfMonth,
  formatISO9075,
  endOfDay,
  startOfDay,
  addDays,
  roundToNearestMinutes,
  differenceInMinutes,
  parse,
  formatISO,
  isMatch,
  isWithinInterval,
  differenceInCalendarDays,
  getWeek,
  getDate,
  getYear,
  isLeapYear,
  setYear,
  isBefore,
  isSameYear,
  isAfter,
  startOfTomorrow,
} from 'date-fns';

import type { EventTimeModel } from '@/types';

export class DateHelper {
  //NOTE: Returns current date/time
  public static getUtcNow = (minus?: number): Date => {
    const date = new Date();
    const nowUtc = Date.UTC(
      minus ? date.getUTCFullYear() - minus : date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    );

    return new Date(nowUtc);
  };

  //NOTE: Returns the date and time based on the passed, but in UTC 0
  public static getUtcZero = (value: string): string => {
    return new Date(value).toLocaleString('en-US', {
      timeZone: 'UTC',
    });
  };

  //NOTE: Returns the utc date in ISO
  public static getDateInUtc = (value: Date): string => {
    return new Date(value.toLocaleString('en-US', { timeZone: 'UTC' })).toISOString();
  };

  //NOTE: Returns the current ISO datetime
  public static getIsoNow = (): string => {
    return this.getUtcNow().toISOString();
  };

  //NOTE: Returns formatted date
  public static formatMaxDate = (maxValue: number): string => {
    //NOTE: bullshit code
    return format(parseISO(this.getUtcNow(maxValue).toISOString()), "yyyy-MM-dd'T'HH:mm:ss");
  };

  //NOTE: Returns formatted date
  public static formatMinDate = (): string => {
    return format(parseISO(this.getUtcNow().toISOString()), "yyyy-MM-dd'T'HH:mm:ss");
  };

  //NOTE: Returns formatted date like a yyyy-MM-dd
  public static formatDate = (value: string): string => {
    return format(parseISO(value), 'yyyy-MM-dd');
  };

  //NOTE: Returns formatted date like a dd-MM without year
  public static formatDateWithoutYear = (value: string): string => {
    return format(parseISO(value), 'dd-MM');
  };

  //NOTE: Returns formatted date like a dd.MM.yyyy HH:mm
  public static formatDateWithTime = (value: string): string => {
    return format(parseISO(value), 'dd.MM.yyyy HH:mm');
  };

  //NOTE: Returns formatted date like a yyyy-MM-dd HH:mm
  public static formatDateWithTimeReverse = (value: string): string => {
    return format(parseISO(value), 'yyyy-MM-dd HH:mm');
  };

  //NOTE: Returns formatted date + added minutes
  public static addMinutesToDate = (value: string, duration: number): Date => {
    const date = new Date(value);
    return addMinutes(date, duration);
  };

  //NOTE: Returns date + added minutes + roundToNearestMinutes like a ISO string
  public static addMinutesToRoundedDateInISO = (value: string, duration: number): string => {
    const date = new Date(value);
    /*//TODO: return for #1548  return DateHelper.formatDateToISO(
      this.roundedDate(addMinutes(date, duration), 30)
    ); */
    return this.roundedDate(addMinutes(date, duration), 30).toISOString();
  };

  //NOTE: Returns date + added minutes like a ISO string
  public static addMinutesToDateInISO = (value: string, duration: number): string => {
    const date = new Date(value);
    return addMinutes(date, duration).toISOString();
  };

  //NOTE: Returns date + added seconds like a ISO string
  public static addSecondsToDateInISO = (value: string, duration: number): string => {
    const date = new Date(value);
    return addSeconds(date, duration).toISOString();
  };

  public static formatDateWithTimeAndAddMinutes = (value: string, duration: number): string => {
    //TODO: return for #1548 return DateHelper.formatDateToISO(this.addMinutesToDate(value, duration));
    return format(this.addMinutesToDate(value, duration), 'yyyy-MM-dd HH:mm');
  };

  //NOTE: Returns formatted date + added days like a ISO string
  public static addDaysToDate = (value: string, duration: number): string => {
    const date = new Date(value);
    return addDays(date, duration).toISOString();
  };

  //NOTE: Returns like day or month or year
  public static formatDateString = (value: string): 'short' | 'day' => {
    const date = new Date(value);
    const today = new Date();

    if (date.getFullYear() !== today.getFullYear()) {
      return 'short';
    } else {
      return date.getDate() !== today.getDate() || date.getMonth() !== today.getMonth() ? 'short' : 'day';
    }
  };

  public static formatDateYearString = (value: string): 'yearNumeric' | 'month' | 'day' => {
    const date = new Date(value);
    const today = new Date();
    if (date.getFullYear() !== today.getFullYear()) {
      return 'yearNumeric';
    } else {
      return date.getDate() !== today.getDate() || date.getMonth() !== today.getMonth() ? 'month' : 'day';
    }
  };

  //NOTE: Returns current month start dateTime in ISO
  public static getCurrentMonthStart = (): string => {
    return formatISO9075(startOfMonth(new Date()));
  };

  //NOTE: Returns current month end dateTime in ISO
  public static getCurrentMonthEnd = (): string => {
    return formatISO9075(endOfMonth(new Date()));
  };

  //NOTE: Returns current day start dateTime in ISO
  public static getCurrentDayStart = (): string => {
    return formatISO9075(startOfDay(new Date()));
  };

  //NOTE: Returns current day end dateTime in ISO
  public static getCurrentDayEnd = (): string => {
    return formatISO9075(endOfDay(new Date()));
  };

  //NOTE: Returns whether the date is past
  public static isPastDate = (value: any): boolean => {
    const date = new Date(value);
    const selectedDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000).getTime();
    const currentDate = new Date().getTime();
    return selectedDate <= currentDate;
  };

  //NOTE: Whether a certain date has passed
  public static isDateInPast = (dateStringInUTC: string): boolean => {
    const currentDate = new Date();
    const targetDate = parseISO(dateStringInUTC);
    return isBefore(targetDate, currentDate);
  };

  //NOTE: Returns rounds the given date to the nearest minute
  /**
   * Rounds the given date to the nearest minute (or number of minutes).
   *
   * @param date
   * @param nearest - The number of minutes to round to
   * @require {nearest} - The number of minutes to be of type NearestMinutes - from 1 to 30
   * @returns
   */
  public static roundedDate = (date: Date, nearest: NearestMinutes): Date => {
    if (nearest < 1 || nearest > 30) {
      throw new Error('The number of minutes to be of type NearestMinutes - from 1 to 30');
    }
    return roundToNearestMinutes(date, { nearestTo: nearest });
  };

  //NOTE: Returns minutes between the given dates
  public static getMinutesBetweenDates = (firstDate: Date, secondDate: Date): number => {
    return differenceInMinutes(secondDate, firstDate);
  };

  //NOTE: Returns date like a ISO string with Timezone ( get string in dd.MM.yyyy HH:mm format)
  public static getDateInIsoWithTimezone = (value: string): string => {
    if (isMatch(value, 'dd.MM.yyyy HH:mm')) {
      const date = parse(value, 'dd.MM.yyyy HH:mm', new Date());
      return formatISO(date);
    }
    return '';
  };

  //NOTE: Returns Is the given date within the interval
  public static isDateWithinInterval = (date: Date, firstDate: Date, secondDate: Date, reset: boolean): boolean => {
    if (reset) {
      return isWithinInterval(date, {
        start: startOfDay(firstDate),
        end: endOfDay(secondDate),
      });
    } else {
      return isWithinInterval(date, { start: firstDate, end: secondDate });
    }
  };

  //NOTE: Returns days between the given dates
  public static getDaysBetweenDates = (firstDate: Date, secondDate: Date): number => {
    return differenceInCalendarDays(secondDate, firstDate);
  };

  //NOTE: Returns the week index of the given date
  public static getCurrentWeek = (): number => {
    return getWeek(new Date(), { weekStartsOn: 1, firstWeekContainsDate: 4 });
  };

  public static getDayOfMonth = (date: string): number => {
    return getDate(new Date(date));
  };

  //NOTE: Returns formatted date like a dd-MM
  public static formatStringToMonth = (value: string): string => {
    if (isMatch(value, 'yyyy-MM-dd')) {
      const date = parse(value, 'yyyy-MM-dd', new Date());
      return format(date, 'dd-MM');
    }
    return value;
  };

  //NOTE: Returns ISO from string like a yyyy-MM-dd
  public static formatMonthStringToISO = (value: string, lapYear: boolean): string => {
    if (isMatch(value, 'yyyy-MM-dd')) {
      const newValue = lapYear ? this.replaceYearWithNearestPastLeapYear(value) : value;
      const parsedDate = new Date(`${newValue}T00:00:00.000Z`);
      parsedDate.setUTCHours(0);

      return parsedDate.toISOString();
    }
    return value;
  };

  //NOTE: Returns Date object, get string dd.MM.yyyy HH:mm
  public static formatStringToDate = (value: string): Date => {
    if (isMatch(value, 'dd.MM.yyyy HH:mm')) {
      return parse(value, 'dd.MM.yyyy HH:mm', new Date());
    }
    return new Date();
  };

  //NOTE: Returns the nearest past leap year
  private static replaceYearWithNearestPastLeapYear = (value: string) => {
    const currentDate = new Date();
    const date = parse(value, 'yyyy-MM-dd', new Date());
    const nearestPastLeapYear = DateHelper.getNearestPastLeapYear(currentDate);
    const newDate = setYear(date, nearestPastLeapYear);
    return format(newDate, 'yyyy-MM-dd');
  };

  //NOTE: Returns nearest past leap year like 'yyyy'
  public static getNearestPastLeapYear = (value: Date): number => {
    let year = getYear(value);
    while (!isLeapYear(setYear(value, year)) && year >= 2020) {
      year--;
    }
    return year;
  };

  //NOTE: Returns nearest past leap year date like string 'yyyy-MM-dd'
  public static getDateWithNearestPastLeapYear = (value: Date): string => {
    let year = getYear(value);
    while (!isLeapYear(setYear(value, year)) && year >= 2020) {
      year--;
    }
    return format(setYear(value, year), 'yyyy-MM-dd');
  };

  public static getHoursIntervalsOfDay = (): EventTimeModel[] => {
    const startDate = startOfDay(new Date());
    const intervals = [] as EventTimeModel[];
    for (let i = 0; i < 96; i++) {
      intervals.push({
        hours: format(addMinutes(startDate, i * 15), 'HH'),
        minutes: format(addMinutes(startDate, i * 15), 'mm'),
      });
    }

    return intervals;
  };

  //NOTE: Returns whether the string is equivalent to the string "yyyy-MM-dd"
  public static isDateString = (value: string): boolean => isMatch(value, 'yyyy-MM-dd');

  //NOTE: Checking that the year of the two dates match
  public static yearsIsEqual = (firstDate: string, secondDate: string): boolean =>
    isSameYear(parseISO(firstDate), parseISO(secondDate));

  //NOTE: Returns the nearest future date in ISO multiple of "minutes" ( ex. 10, 15, 30) minutes
  public static getFutureTime = (value: string, minutes: number): string => {
    try {
      let date = new Date(value);

      const roundedMinutes = Math.ceil(date.getMinutes() / minutes) * minutes;
      date.setMinutes(roundedMinutes);

      if (isAfter(date, new Date())) {
        return date.toISOString();
      } else {
        //NOTE: Если результат попадает в следующий день, то устанавливаем дату на след. день и прибавляем минуты
        date = addMinutes(startOfTomorrow(), minutes);
        return date.toISOString();
      }
    } catch (error) {
      console.error('An error occurred during date generation:', error);
      return value;
    }
  };

  //NOTE: Returns date in ISO 8601
  public static formatDateToISO = (value: Date): string => {
    return format(value, "yyyy-MM-dd'T'HH:mm:ss'Z'");
  };
}
