import type { TopicsIdsModel, TopicsIdsDataModel, TopicEntity } from '@/types';

export const defaultTopicMode: TopicEntity = {
  id: 0,
  createdAt: '',
  title: '',
  description: '',
  label: '',
  followersCount: 0,
  postsCount: 0,
  urlPhoto: null,
  url: '', //? Absent in response
  color: null,
  photoFileUrl: null, //? Absent in response
  webUrl: '', //? Absent in response
};

export const defaultTopicsIds: TopicsIdsModel = {
  topicsPage: {
    all: { loadMoreUrl: null, ids: [] } as TopicsIdsDataModel,
    recommended: { loadMoreUrl: null, ids: [] } as TopicsIdsDataModel,
    following: { loadMoreUrl: null, ids: [] } as TopicsIdsDataModel,
    search: { loadMoreUrl: null, ids: [] } as TopicsIdsDataModel,
  },
  searchPage: { loadMoreUrl: null, ids: [] } as TopicsIdsDataModel,
};
