import { merge } from 'lodash';
import { nextTick } from 'vue';
import type { Composer, I18n, I18nOptions, Locale } from 'vue-i18n';
import { createI18n } from 'vue-i18n';

let vueI18n: I18n;

export const DEFAULT_LOCALE = import.meta.env.VITE_APP_DEFAULT_LOCALE;
export const SUPPORT_LOCALES = import.meta.env.VITE_APP_SUPPORT_LOCALES.split('|');

export function useI18n(): Composer {
  if (vueI18n === undefined) {
    initI18n();
  }
  return vueI18n.global as unknown as Composer;
}

export function _setLocale(i18n: I18n, locale: Locale): void {
  (i18n.global as unknown as Composer).locale.value = locale;
}

export function _setI18nLanguage(i18n: I18n, locale: Locale): void {
  _setLocale(i18n, locale);
  document.querySelector('html')?.setAttribute('lang', locale);
}

export function _setupI18n(options: I18nOptions): I18n {
  const i18n = createI18n(options);
  _setI18nLanguage(i18n, DEFAULT_LOCALE);
  return i18n;
}

export function initI18n(): I18n {
  if (vueI18n === undefined) {
    vueI18n = _setupI18n({
      legacy: false,
      locale: DEFAULT_LOCALE,
      fallbackLocale: DEFAULT_LOCALE,
    });
  }
  return vueI18n;
}

//?: even in use?
export function getLocale(): string {
  return (vueI18n.global as unknown as Composer).locale.value;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const _getResourceMessages = (r: any) => r.default || r;

export async function loadLocaleMessages(
  i18n: Composer,
  locale: Locale,
  resources: any = {},
  useBKGLocales = false,
  useAWOLocales = false
): Promise<void> {
  // Check if locale is supported, fallback if necessary
  if (!SUPPORT_LOCALES.includes(locale)) {
    console.warn(`Locale '${locale}' is not supported, falling back to default locale '${DEFAULT_LOCALE}'`);
    locale = DEFAULT_LOCALE;
  }

  // Load base locale messages
  const locales = await import(`./locales/${locale}.json`).then(_getResourceMessages);

  // Conditionally load and merge BKG and AWO locale resources
  if (useBKGLocales) {
    const BKGLocales = await import(`./locales/BKG/${locale}.json`).then(_getResourceMessages);
    resources = merge({}, resources, BKGLocales);
  }

  if (useAWOLocales) {
    const AWOLocales = await import(`./locales/AWO/${locale}.json`).then(_getResourceMessages);
    resources = merge({}, resources, AWOLocales);
  }

  // Merge loaded locales with additional resources
  const finalLocales = merge({}, locales, resources);

  // Set locale messages and apply the language
  i18n.setLocaleMessage(locale, finalLocales);
  _setI18nLanguage(vueI18n, locale);

  await nextTick();
}
